import React, { Component } from "react";
import InfoHolder from "./Styles/infoGeneralStyle";
import { Col } from "antd";
import image1 from "../images/sin-intermediarios.png";
import image2 from "../images/sin-llamadas.png";
import image3 from "../images/100-online.png";
import client1 from "../images/brands/1.png";
import client2 from "../images/brands/2.png";
import client3 from "../images/brands/3.png";
import client4 from "../images/brands/4.png";
import client5 from "../images/brands/5.png";
import client6 from "../images/brands/6.png";
import client7 from "../images/brands/7.png";
import client8 from "../images/brands/8.png";
import client9 from "../images/brands/9.png";
import client10 from "../images/brands/10.png";
import client11 from "../images/brands/11.png";
import client12 from "../images/brands/12.png";
import client13 from "../images/brands/13.png";
import client14 from "../images/brands/14.png";
import client15 from "../images/brands/15.png";
import client16 from "../images/brands/16.png";
import client19 from "../images/agencies/1.png";
import client20 from "../images/agencies/2.png";
import client21 from "../images/agencies/3.png";
import client22 from "../images/agencies/4.png";
import client23 from "../images/agencies/5.png";
import client24 from "../images/agencies/6.png";
import client25 from "../images/agencies/7.png";
import client26 from "../images/agencies/8.png";

import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";
import { default as SliderSlick } from "react-slick";

class Info extends Component {
  state = {
    videoLatinadVisible: false,
    videoPublinetVisible: false
  };

  constructor(props) {
    super(props);
    this.video = React.createRef();
    this.clients = [
      client1,
      client2,
      client3,
      client4,
      client19,
      client5,
      client6,
      client22,
      client7,
      client8,
      client9,
      client25,
      client10,
      client11,
      client12,
      client13,
      client14,
      client15,
      client16,
      client20,
      client21,
      client23,
      client24,
      client26,
    ]
  }

  render() {
    const { t } = this.props;
    var settings = {
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <InfoHolder>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          justify="center"
          align="middle"
          style={{ marginTop: "100px", marginBottom: "60px", paddingLeft: "2%", paddingRight: "2%" }}
        >
          <h1 style={{color: Colors.blueDark}}>{t('Discover the latest in outdoor advertising')}</h1>
          <h2>{t('Communicate your brand to thousands of people intelligently')}</h2>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <img
              src={image1}
              style={{ width: "197px", paddingTop: "11%" }}
              onClick={this.showModalVideoLatinad}
              alt=""
            />
            <h2 style={{ marginTop: "50px" }}>
              {t('Without intermediaries')}
            </h2>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <img
              src={image2}
              style={{ width: "180px", paddingTop: "11%" }}
              onClick={this.showModalVideoLatinad}
              alt=""
            />
            <h2 style={{ marginTop: "50px" }}>
              {t('Without calls')}
            </h2>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <img
              src={image3}
              style={{ width: "180px", paddingTop: "11%" }}
              onClick={this.showModalVideoLatinad}
              alt=""
            />
            <h2 style={{ marginTop: "50px" }}>
              {t('100% Online')}
            </h2>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}
          style={{ marginBottom: "60px" }}
        >
            <div className="sliderContainer" style={{marginRight: "10%", marginLeft: "10%"}}>
              <SliderSlick {...settings}>
                {this.clients.map((client, index) => (
                  <div className="sliderCard" key={index}>
                    <div className="sliderCardContent">
                      <img
                        className="imagePartner"
                        alt="displayers"
                        src={client}
                      />
                    </div>
                  </div>
                ))}
              </SliderSlick>
            </div>
          </Col>
      </InfoHolder>
    );
  }
}
export default withTranslation()(Info);
