import React, { Component } from "react";
import { Row, Col, Icon, notification } from "antd";
import { trackOutboundLink } from '../middlewares/google-analytics.js';
import FooterHolder from "./Styles/footerStyle";
import { withTranslation } from 'react-i18next';
import UrlEnum from "../constants/UrlEnum";

class Footer extends Component {

  openZoomAlert = placement => {
    notification.info({
      message: this.props.t('You can start creating your campaign by entering the zone and date.'),
      duration: 7,
      placement
    });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <FooterHolder>
        <Row type="flex" justify="center" className="footerRow">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} justify="center">
            <Row>
              <h2>{t('Advertisers')}</h2>
              <div>
                <p>
                  <a href="#" onClick={() => this.openZoomAlert('topRight')}>
                  {t('Create campaign')}
                  </a>
                </p>
                <p>
                  {(window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com') ? (
                    <a target="_blank" href={t('Url whatsapp, I am an agency latinad')} rel="noopener noreferrer" onClick={(e) => trackOutboundLink(t('Url whatsapp, I am an agency latinad'), e)}>
                      {t('I am an agency')}
                    </a>
                  ) : (
                    <a target="_blank" href={t('Url whatsapp, I am an agency publinet')} rel="noopener noreferrer" onClick={(e) => trackOutboundLink(t('Url whatsapp, I am an agency publinet'), e)}>
                      {t('I am an agency')}
                    </a>
                  )}
                </p>
                <p>
                  {(window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com') ? (
                    <a target="_blank" href={t('Url whatsapp, Purchase advice latinad')} rel="noopener noreferrer" onClick={(e) => trackOutboundLink(t('Url whatsapp, Purchase advice latinad'), e)}>
                      {t('Purchase advice')}
                    </a>
                  ) : (
                    <a target="_blank" href={t('Url whatsapp, Purchase advice publinet')} rel="noopener noreferrer" onClick={(e) => trackOutboundLink(t('Url whatsapp, Purchase advice publinet'), e)}>
                      {t('Purchase advice')}
                    </a>
                  )}
                </p>
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Row>
              <h2>{t('Screen owners')}</h2>
              <div>
                <p>
                  <a target="_blank" rel="noopener noreferrer" href={(
                    window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com'
                    ) ? (
                      i18n.language == 'es' ? "https://gestor.latinad.com/" : "https://gestor.latinad.com/"
                    ) : (
                      window.location.hostname === 'publinet.us' || window.location.hostname.split('.').splice(1).join('.') === 'publinet.us'
                    ) ? "https://publinet.us/cms/" : "https://gestor.latinad.com/"
                    }
                  >
                    {(window.location.hostname === 'publinet.us' || window.location.hostname.split('.').splice(1).join('.') === 'publinet.us'
                    ) ? "CMS" : t('Screen manager')}
                  </a>
                </p>
                <p>
                  <a target="_blank" rel="noopener noreferrer" href={(
                      window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com'
                      ) ? (
                        (i18n.language == 'es' || i18n.language == 'es_ar') ? UrlEnum.landingEs:"https://en.latinad.com"
                      ) : (
                        "https://publinet.us/media/"
                    )}
                  >
                    {t('Offer your screens')}
                  </a>
                </p>
                <p>
                  <a target="_blank" rel="noopener noreferrer" href={(
                      window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com'
                      ) ? (
                        (i18n.language == 'es' || i18n.language == 'es_ar') ? "https://latinad.com/medios/ssp.html" : "https://en.latinad.com/ssp.html"
                      ) : (
                        "https://publinet.us/ssp.html"
                    )}
                  >
                    {t('SSP')}
                  </a>
                </p>
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Row>
              <h2>{t('Contact')}</h2>
              <div>
                <p>
                  {(window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com') ? (
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=5492615019508" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://api.whatsapp.com/send?phone=5492615019508', e)}>
                      {t('Write us by whatsapp')}
                    </a>
                  ) : (window.location.hostname === 'fenix.publinet.us' || window.location.hostname.split('.').splice(1).join('.') === 'fenix.publinet.us') ? (
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=14073752728" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://api.whatsapp.com/send?phone=14073752728', e)}>
                      {t('Write us by whatsapp')}
                    </a>
                  ) : (window.location.hostname === 'dooh.duoprint.com.ec' || window.location.hostname.split('.').splice(1).join('.') === 'dooh.duoprint.com.ec') ? (
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=593999560300" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://api.whatsapp.com/send?phone=0999560300', e)}>
                      {t('Write us by whatsapp')}
                    </a>
                  ) : (
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=5492615019508" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://api.whatsapp.com/send?phone=5492615019508', e)}>
                      {t('Write us by whatsapp')}
                    </a>
                  )}
                </p>
                <p>
                  {(window.location.hostname === 'latinad.com' || window.location.hostname.split('.').splice(1).join('.') === 'latinad.com') ? (
                    <a href="tel:+5492615019508">{t('Speak with us')}</a>
                  ) : (window.location.hostname === 'fenix.publinet.us' || window.location.hostname.split('.').splice(1).join('.') === 'fenix.publinet.us') ? (
                    <a href="tel:+14073752728">{t('Speak with us')}</a>
                  ) : (
                    <a href="tel:+5492615019508">{t('Speak with us')}</a>
                  )}
                </p>
                <p>
                  <a target="_blank" href={t('Mailto Work with us')} rel="noopener noreferrer">
                    {t('Work with us')}
                  </a>
                </p>
                <p>
                  <a target="_blank" href="https://help.latinad.com/" rel="noopener noreferrer">
                    {t('Help')}
                  </a>
                </p>
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Row>
              <h2>{t('Our social networks')}</h2>
              <div>
                <p>
                  {t('Find out about the latest news of the platform on our social networks.')}
                </p>
                <a target="_blank" href="https://www.facebook.com/LatinAdDOOH" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://www.facebook.com/LatinAdDOOH', e)}>
                  <Icon
                    style={{
                      fontSize: "25px",
                      color: "#424242"
                    }}
                    type="facebook"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/latin.ad/" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://www.instagram.com/latin.ad/', e)}>
                  <Icon
                    style={{
                      fontSize: "25px",
                      marginLeft: "10px",
                      color: "#424242"
                    }}
                    type="instagram"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/latin.ad/" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://www.linkedin.com/company/latinad', e)}>
                  <Icon
                    style={{
                      fontSize: "25px",
                      marginLeft: "10px",
                      color: "#424242"
                    }}
                    type="linkedin"
                  />
                </a>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=5492615019508" rel="noopener noreferrer" onClick={(e) => trackOutboundLink('https://api.whatsapp.com/send?phone=5492615019508', e)}>
                  <Icon
                    style={{
                      fontSize: "25px",
                      marginLeft: "10px",
                      color: "#424242"
                    }}
                    type="phone"
                  />
                </a>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row
              className="disclaimer"
              type="flex"
              justify="center"
              align="middle"
            >
              © {(new Date().getFullYear())} LatinAd. All rights reserved.
            </Row>
          </Col>
        </Row>
      </FooterHolder>
    );
  }
}
export default withTranslation()(Footer);
