import { connect } from 'react-redux'
import CartAudienceComponent from '../components/CartAudienceComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from '../redux/UserAccountRedux'

const mapStateToProps = ({ display , userAccount}) => ({
  cart: display.cart,
  date_from: display.date_from,
  date_to: display.date_to,
  audience: display.audience,
  loadingAudience: display.loadingAudience,
  currentCampaign: display.currentCampaign,
  customContents: display.customContents,
  amountBudget: display.amountBudget,
  programmatic: display.programmatic,
  user: userAccount.user,
  editCampaignActive: display.editCampaignActive,
  amountBudget: display.amountBudget,
  realAmount: display.realAmount,
  dsp: display.dsp,
  currencyExchangeRate: display.currencyExchangeRate,
})

const mapStateToDispatch = (dispatch) => ({
  addDisplayToCart: (display) => dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  createQuote: (quoteData) => dispatch(DisplayActions.createQuoteRequest(quoteData)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  cleanAudience: ()=> dispatch(DisplayActions.cleanAudience()),
})

export default connect(mapStateToProps, mapStateToDispatch)(CartAudienceComponent)
