import styled from "styled-components";
import Colors from "../../themes/Colors";


const HomeHolder = styled.div`
  .customImage .heroContainer {
    height: 100vh !important;
  }
  .smartFit .heroContainer {
    background-image: linear-gradient(0deg, #000000, #bfbfbf);
  }
  .territorioDigital .heroContainer {
    background-image: linear-gradient(314deg,#f4a8031b,#F4A703)
  }
  .mediaShake .heroContainer {
    background-image: linear-gradient(49deg, #5d065c, #ef5e3a);
  }
  .duoPrint .heroContainer {
    background-image: linear-gradient(0deg, #7BD2F5, #7BD2F5);
  }
  .movImagen .heroContainer {
    background-image: linear-gradient(0deg, #FF6900, #FF6900);
  }
  .heroContainer {
    height: 760px;
    min-height: 75vh;

    ${'' /* background-image: url(${homeBkTst}); */}
    background-image: linear-gradient(0deg, ${Colors.primary}, ${Colors.backgroundSecondary});

    // .bg {
    //   height: 100%;
    //   left: 0;
    //   object-fit: cover;
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    //   z-index: 1;
    // }
    //
    // .gradient {
    //   border-radius: 100%;
    //   filter: blur(7rem);
    //   height: 80rem;
    //   opacity: 1;
    //   position: absolute;
    //   width: 95rem;
    // }
    //
    // .gradient.top_right {
    //   left: 28vw;
    //   top: -36vw;
    //   z-index: 1;
    //   background: linear-gradient(0deg,#3F51B5 3.3%,#0505a9 60.14%,#0539c0 69.11%,#0585e2 82.08%,#05b6f7 92.05%,#05c8ff 97.03%);
    // }
    // .gradient.bottom_right {
    //   background: linear-gradient(0deg,#05b6f7 3.3%,#0505a9 60.14%,#0539c0 69.11%,#0585e2 82.08%,#05b6f7 92.05%,#05c8ff 97.03%);
    //   left: 34vw;
    //   top: 6rem;
    //   z-index: 4;
    // }
    // .gradient.bottom_center {
    //   background: linear-gradient(104.96deg,#05b6f7 6.46%,#05b6f7 18.88%,#0505a9 50.46%,#0539c0 57.4%,#0585e2 67.44%,#05b6f7 75.15%,#05c8ff 79.01%);
    //   left: calc(30% - 23rem);
    //   top: 8rem;
    //   z-index: 3;
    // }
    // .gradient.middle_left {
    //   left: -22vw;
    //   top: calc(50% - 60rem);
    //   transform: rotate(-1.53deg);
    //   z-index: 2;
    //   background: linear-gradient(0deg,#05b6f7 3.3%,#0505a9 60.14%,#0539c0 69.11%,#0585e2 82.08%,#05b6f7 92.05%,#05c8ff 97.03%);
    // }


    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .containerInfo {
    padding-top: 2.5%;
    @media only screen and (max-height: 650px) {
      padding-top: 5%;
    }
  }
  .mainTextSEO {
    color: ${Colors.snowSecondary}
    font-size: 16px !important;
    line-height: 32px !important;
    margin-bottom: 8px !important;
    max-width: 300px !important;
  }
  .mainTextMobileSEO {
    color: ${Colors.snowSecondary}
    font-size: 16px !important;
    line-height: 32px !important;
    margin-bottom: 15px !important;
    max-width: none !important;
  }
  .mainText {
    color: ${Colors.snowSecondary}
    font-size: 32px !important;
    line-height: 32px !important;
    margin-bottom: 12px !important;
    max-width: 300px !important;
  }
  .secondaryText {
    color: ${Colors.snowSecondary}
    padding-right: 50%;
    font-size: 18px !important;
  }
  .moreInfoButton {
    height: 35px;
    margin: 0 0 15px;
    background-color: #fff;
    border-color: #3a96f3;
    border-radius: 5px;
    color: #3a96f3;
    font-size: 16px;
    font-weight: 700;
  }
  .moreInfoButton:hover {
    background-color: #ececec;
    color: #3a96f3;
  }

  // Mobile view
  .containerInfoMobile {
    padding-top: 8%;
    text-align: center;
  }
  .mainTextMobile {
    color: ${Colors.snowSecondary}
    font-size: 30px !important;
    line-height: 32px !important;
    margin-bottom: 15px !important;
    max-width: none !important;
  }
  .secondaryTextMobile {
    color: ${Colors.snowSecondary}
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }
  .moreInfoButtonMobile {
    height: 35px;
    margin: 0 0 15px;
    background-color: #fff;
    border-color: #3a96f3;
    border-radius: 5px;
    color: #3a96f3;
    font-size: 16px;
    font-weight: 700;
  }
  .moreInfoButtonMobile:hover {
    background-color: #ececec;
    color: #3a96f3;
  }

  .mainImage {
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;
    bottom: 0;

    @media only screen and (min-width: 1280px) and (max-height: 680px) {
      width: 60%;
    }
    @media only screen and (max-width: 576px) and (max-height: 680px) {
      display: none;
    }
    @media only screen and (min-width: 1600px) {
      width: 70%;
    }
    @media only screen and (max-width: 900px){
      display: none !important;
    }
  }

  .customImage .mainImage {
    height: 100%;
    @media only screen and (min-width: 1280px) and (max-height: 680px) {
      width: 100%;
      height: 100%;
    }
    @media only screen and (max-width: 576px) and (max-height: 680px) {
      display: none;
    }
    @media only screen and (max-width: 2000px) {
     top: 0;
    }
    @media only screen and (min-width: 1400px) {
      width: 100%;
      height: 100%;
    }
  }
  .gif {
    position: absolute;
    top: 291px;
    position: absolute;
    left: 53%;
    width: 21%;
    z-index: 2;

    @media only screen and (min-width: 1280px) {
      width: 21%;
    }
    @media only screen and (max-height: 768px) {
      width: 21%;
    }
    @media only screen and (max-height: 768px) {
      width: 21%;
      left: 50%;
    }
  }

  .circle-shape {
    position: absolute;
    top: -55%;
    left: -13%;
    transform:translate3d(0px, 0.029px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
    -webkit-transform:translate3d(0px, 0.029px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
  }

  .particale-element {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    position: initial;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .particale-element li {
    position: absolute;
  }
  .section1 li:first-child {
    top: -16%;
    left: 40px;
    -webkit-animation: o 5s infinite linear;
    animation: o 5s infinite linear;
  }
  .section1 li:nth-child(2) {
    top: 48%;
    left: 95%;
    background: #42a5f5;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: t 20s infinite linear;
    animation: t 20s infinite linear;
  }
  .section2 li:first-child {
    top: -8%;
    left: 4%;
    background: #42a5f5;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: x 20s infinite linear;
    animation: x 20s infinite linear;
  }
  ${'' /* .section2 li:nth-child(2) {
    left: 91%;
    top: -32%;
    background: #e3f2fd;
    height: 600px;
    width: 600px;
    border-radius: 50%;
    -webkit-animation: g 8s infinite linear;
    animation: g 8s infinite linear;
  } */}
  .section2 li:nth-child(2) {
    right: 20%;
    top: -7%;
    background: rgba(227, 242, 253, 0.5);
    height: 300px;
    width: 300px;
    border-radius: 50%;
    -webkit-animation: g 6s infinite linear;
    animation: g 6s infinite linear;
  }
  .section3 li:first-child {
    bottom: -13%;
    left: 33%;
    z-index: 0;
    -webkit-animation: g 6s infinite linear;
    animation: g 6s infinite linear;
  }
  .section3 li:nth-child(2) {
    right: 90%;
    top: -2%;
    background: #e3f2fd;
    height: 600px;
    width: 600px;
    border-radius: 50%;
    -webkit-animation: g 8s infinite linear;
    animation: g 8s infinite linear;
  }
  .section3 li:nth-child(3) {
    right: 20%;
    top: -7%;
    background: rgba(227, 242, 253, 0.5);
    height: 300px;
    width: 300px;
    border-radius: 50%;
    -webkit-animation: g 6s infinite linear;
    animation: g 6s infinite linear;
  }

  .section5 {
    position: absolute;
  }
  .section5 li:first-child {
    top: 1%;
    left: 3%;
    background: #42a5f5;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: x 20s infinite linear;
    animation: x 20s infinite linear;
  }
  .section5 li:nth-child(2) {
    top: 58%;
    left: 44%;
    background: #42a5f5;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: x 10s infinite linear;
    animation: x 10s infinite linear;
  }
  .section5 li:nth-child(3) {
    right: 40%;
    bottom: -18%;
    background: rgba(227, 242, 253, 0.5);
    height: 300px;
    width: 300px;
    border-radius: 50%;
    -webkit-animation: g 6s infinite linear;
    animation: g 6s infinite linear;
  }

  ${'' /* .section2 li:nth-child(2) {
    top: 12%;
    left: 30%;
    background: #42a5f5;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: t 20s infinite linear;
    animation: t 20s infinite linear;
  }
  .section2 li:nth-child(3) {
    left: 8%;
    top: 35%;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 20px solid #64b5f6;
    -webkit-animation: x 20s infinite linear;
    animation: x 20s infinite linear;
    display: none;
  }
  .section2 li:nth-child(4) {
    bottom: 20%;
    left: 63%;
    background: #42a5f5;
    height: 15px;
    width: 15px;
    border-radius: 30px;
    -webkit-animation: u 20s infinite linear;
    animation: u 20s infinite linear;
  }
  .section2 li:nth-child(5) {
    bottom: -81%;
    left: -19%;
    background: #bbdefb;
    height: 500px;
    width: 500px;
    border-radius: 50%;
    -webkit-animation: g 3s infinite linear;
    animation: g 3s infinite linear;
  }
  .section2 li:nth-child(6) {
    bottom: -36%;
    left: 33%;
    z-index: 1;
    -webkit-animation: g 6s infinite linear;
    animation: g 6s infinite linear;
  }
  .section2 li:nth-child(7) {
    right: -28%;
    top: -19%;
    background: #e3f2fd;
    height: 600px;
    width: 600px;
    border-radius: 50%;
    -webkit-animation: g 8s infinite linear;
    animation: g 8s infinite linear;
  }
  .section2 li:nth-child(8) {
    right: 22%;
    top: -17%;
    background: rgba(227, 242, 253, 0.5);
    height: 360px;
    width: 360px;
    border-radius: 50%;
    -webkit-animation: g 6s infinite linear;
    animation: g 6s infinite linear;
  } */}

  @-webkit-keyframes u{
    0%{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
    20%{
        -webkit-transform:translate(73px,1px) rotate(36deg);
        transform:translate(73px,1px) rotate(36deg)
    }
    40%{
        -webkit-transform:translate(141px,72px) rotate(72deg);
        transform:translate(141px,72px) rotate(72deg)
    }
    60%{
        -webkit-transform:translate(83px,122px) rotate(108deg);
        transform:translate(83px,122px) rotate(108deg)
    }
    80%{
        -webkit-transform:translate(-40px,72px) rotate(144deg);
        transform:translate(-40px,72px) rotate(144deg)
    }
    to{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
  }
  @keyframes u{
    0%{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
    20%{
        -webkit-transform:translate(73px,1px) rotate(36deg);
        transform:translate(73px,1px) rotate(36deg)
    }
    40%{
        -webkit-transform:translate(141px,72px) rotate(72deg);
        transform:translate(141px,72px) rotate(72deg)
    }
    60%{
        -webkit-transform:translate(83px,122px) rotate(108deg);
        transform:translate(83px,122px) rotate(108deg)
    }
    80%{
        -webkit-transform:translate(-40px,72px) rotate(144deg);
        transform:translate(-40px,72px) rotate(144deg)
    }
    to{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
  }
  @keyframes g{
    0%{
        -webkit-transform:rotate(0deg) translate3d(0,3%,0) rotate(0deg);
        transform:rotate(0deg) translate3d(0,3%,0) rotate(0deg)
    }
    to{
        -webkit-transform:rotate(1turn) translate3d(0,3%,0) rotate(-1turn);
        transform:rotate(1turn) translate3d(0,3%,0) rotate(-1turn)
    }
  }
  @-webkit-keyframes x{
    0%{
        -webkit-transform:translate(0) rotate(0deg) scale(1);
        transform:translate(0) rotate(0deg) scale(1)
    }
    20%{
        -webkit-transform:translate(73px,-1px) rotate(36deg) scale(.9);
        transform:translate(73px,-1px) rotate(36deg) scale(.9)
    }
    40%{
        -webkit-transform:translate(141px,72px) rotate(72deg) scale(1);
        transform:translate(141px,72px) rotate(72deg) scale(1)
    }
    60%{
        -webkit-transform:translate(83px,122px) rotate(108deg) scale(1.2);
        transform:translate(83px,122px) rotate(108deg) scale(1.2)
    }
    80%{
        -webkit-transform:translate(-40px,72px) rotate(144deg) scale(1.1);
        transform:translate(-40px,72px) rotate(144deg) scale(1.1)
    }
    to{
        -webkit-transform:translate(0) rotate(0deg) scale(1);
        transform:translate(0) rotate(0deg) scale(1)
    }
  }
  @keyframes x{
    0%{
        -webkit-transform:translate(0) rotate(0deg) scale(1);
        transform:translate(0) rotate(0deg) scale(1)
    }
    20%{
        -webkit-transform:translate(73px,-1px) rotate(36deg) scale(.9);
        transform:translate(73px,-1px) rotate(36deg) scale(.9)
    }
    40%{
        -webkit-transform:translate(141px,72px) rotate(72deg) scale(1);
        transform:translate(141px,72px) rotate(72deg) scale(1)
    }
    60%{
        -webkit-transform:translate(83px,122px) rotate(108deg) scale(1.2);
        transform:translate(83px,122px) rotate(108deg) scale(1.2)
    }
    80%{
        -webkit-transform:translate(-40px,72px) rotate(144deg) scale(1.1);
        transform:translate(-40px,72px) rotate(144deg) scale(1.1)
    }
    to{
        -webkit-transform:translate(0) rotate(0deg) scale(1);
        transform:translate(0) rotate(0deg) scale(1)
    }
  }
  @-webkit-keyframes t{
    0%{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
    20%{
        -webkit-transform:translate(-73px,1px) rotate(-36deg);
        transform:translate(-73px,1px) rotate(-36deg)
    }
    40%{
        -webkit-transform:translate(-141px,-72px) rotate(-72deg);
        transform:translate(-141px,-72px) rotate(-72deg)
    }
    60%{
        -webkit-transform:translate(-83px,-122px) rotate(-108deg);
        transform:translate(-83px,-122px) rotate(-108deg)
    }
    80%{
        -webkit-transform:translate(40px,-72px) rotate(-144deg);
        transform:translate(40px,-72px) rotate(-144deg)
    }
    to{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
  }
  @keyframes t{
    0%{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
    20%{
        -webkit-transform:translate(-73px,1px) rotate(-36deg);
        transform:translate(-73px,1px) rotate(-36deg)
    }
    40%{
        -webkit-transform:translate(-141px,-72px) rotate(-72deg);
        transform:translate(-141px,-72px) rotate(-72deg)
    }
    60%{
        -webkit-transform:translate(-83px,-122px) rotate(-108deg);
        transform:translate(-83px,-122px) rotate(-108deg)
    }
    80%{
        -webkit-transform:translate(40px,-72px) rotate(-144deg);
        transform:translate(40px,-72px) rotate(-144deg)
    }
    to{
        -webkit-transform:translate(0) rotate(0deg);
        transform:translate(0) rotate(0deg)
    }
  }
  @-webkit-keyframes o{
    0%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:0
    }
    50%{
        -webkit-transform:translateY(-20px);
        transform:translateY(-20px);
        opacity:1
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:0
    }
  }
  @keyframes o{
    0%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:0
    }
    50%{
        -webkit-transform:translateY(-20px);
        transform:translateY(-20px);
        opacity:1
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:0
    }
  }

`;

export default HomeHolder;
