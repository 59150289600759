import styled from "styled-components";

const RefinateFiltersFormHolder = styled.div`
  padding: 32px;

  @media only screen and (max-width: 768px) {
    padding: 8px;
  }

  .inputSelect {
    width: 100%;
    padding: 0 10px 0 10px;
  }

  .noWrap {
    /* flex-wrap: nowrap; */
    padding-bottom: 20px;
  }
  .filterButtonModal {
    width: 100%;
    padding: 0 10px 0 10x;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 900px) {
      justify-content: center;
    }
  }
  .filterButtonModal:hover {
    background-color: #f2f2f2;
  }
  .filterButtonModal.ant-popover-open {
    background-color: #f2f2f2;
  }
  .filterButtonModalDates {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filterButtonModalDate {
    width: 100%;
  }
  .filterCurrency span {
    font-size: 11px !important;
  }
  .;
`;

export default RefinateFiltersFormHolder;
