import { connect } from 'react-redux'
import QuotesComponent from '../components/QuotesComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountRedux from '../redux/UserAccountRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  quotes: display.quotes,
  quote: display.quote,
  editingQuote: display.editingQuote,
  editQuoteSuccess: display.editQuoteSuccess,
  editQuoteFailure: display.editQuoteFailure,
  user: userAccount.user,
  fetching: display.fetching,
  fetchingQuote: display.fetchingQuote,
  fetchingDeleteQuote: display.fetchingDeleteQuote,
  fetchingArchivedQuote: display.fetchingArchivedQuote,
  response: display.response,
  errors: display.errors,
  cart: display.cart,
  countries: userAccount.countries,
  dsps: display.dsps,
})

const mapStateToDispatch = (dispatch) => ({
  getQuotes: (userId, params) => dispatch(DisplayActions.getQuotesRequest(userId, params)),
  editQuote: (quoteId, quote) => dispatch(DisplayActions.editQuoteRequest(quoteId, quote)),
  setArchivedQuote: (quoteId, archived) => dispatch(DisplayActions.setArchivedQuoteRequest(quoteId, archived)),
  getQuote: (token, client, brand, cart, dsp) => dispatch(DisplayActions.getQuoteRequest(token, client, brand, cart, dsp)),
  setDsp: (dsp) => dispatch(DisplayActions.setDsp(dsp)),
  deleteQuote: (quoteId) => dispatch(DisplayActions.deleteQuoteRequest(quoteId)),
  addDisplayToCart: (display) => dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  setBilling: (currency, country, codeCountry)=> dispatch(UserAccountRedux.setBilling(currency, country, codeCountry)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountRedux.setShouldPromptLogin(value, target)),

})

export default connect(mapStateToProps, mapStateToDispatch)(QuotesComponent)
