import React, { Component } from "react";
import {
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
} from "antd";
import moment from "moment";
import { withTranslation } from "react-i18next";
import RefinateFiltersFormHolder from "./Styles/refinateFiltersFormStyle";
import { hasAccessRole } from "../services/utils";
import UserRoleEnum from "../constants/UserRoleEnum";
import typeScreenEnum from "../constants/typeScreenEnum";
import { difference } from "lodash";
import { getLocationType } from "../services/display"

const { RangePicker } = DatePicker;
const { Option } = Select;
class RefinateFiltersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmall: this.props.size_type.includes("small"),
      isMedium: this.props.size_type.includes("medium"),
      isBig: this.props.size_type.includes("big"),
      isGiant: this.props.size_type.includes("giant"),
      list_sub_type: []
    };
  }

  async handlePriceChange(price) {
    await this.setState({ price });
    this.props.setPrice(price);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location_type != this.props.location_type) {
      this.setState({ list_sub_type: this.renderIdentificationSubType() })

      // chek if delete select in type
      if (nextProps.location_type.length > this.props.location_type.length) {
        let deleteType = difference(nextProps.location_type, this.props.location_type);
        let aux_sub_type = this.props.location_sub_type
        typeScreenEnum.map((type) => {
          if (type.key == deleteType[0]) {
            type.value.map((value) => {
              aux_sub_type = aux_sub_type.filter(list => list != value)
              aux_sub_type = aux_sub_type.length ? aux_sub_type : []
            })
          };
        });
        this.props.setLocationSubType(aux_sub_type);
      }
    }
  }

  handleDateChange = (dateStrings) => {
    const { setDates } = this.props;
    let startDate = moment(dateStrings[0]);
    let endDate = moment(dateStrings[1]);
    setDates(
      startDate && startDate.format("YYYY-MM-DD"),
      endDate && endDate.format("YYYY-MM-DD")
    );
  };

  async handleTypeChange(type) {
    this.props.setLocationType([...type]);
  }

  async handleSubTypeChange(subType) {
    this.props.setLocationSubType([...subType]);
  }

  async handleSizeChange(size) {
    await this.setState({ [size]: !this.state[size] });

    if (this.state[size]) {
      this.props.setSizeType([...this.props.size_type, size]);
    } else {
      this.props.setSizeType(this.props.size_type.filter((t) => t !== size));
    }
  }

  renderIdentificationSubType = () => {
    const { location_type, t } = this.props
    var list_select = []
    return typeScreenEnum.map((type) => {
      if (location_type.find(location_type => location_type === type.key)) {
        return type.value.map((sub_type) => {
          if (list_select.length === 0 || !list_select.find(list => list === sub_type)) {
            list_select.push(sub_type)
            return (
              <Option key={sub_type} value={sub_type}>
                {t("subtype_" + sub_type)}
              </Option>
            );
          }
        })
      };
    });
  };

  renderIdentificationType = () => {
    return typeScreenEnum.map((type) => {
      return (
        <Option key={type.key} value={type.key}>
          {getLocationType(type.key, this.props.t)}
        </Option>
      );
    });
  };

  render() {
    const {
      t,
      changeClient,
      changeBrand,
      changeCountries,
      renderIdentificationClients,
      renderIdentificationBrands,
      renderIdentificationCountries,
      date_from,
      date_to,
      size_type,
      location_type,
      user,
      isCircuit,
      setCircuit
    } = this.props;

    const {
      isBig,
      isMedium,
      isSmall,
      isGiant
    } = this.state;

    let isAgency = false;
    if (
      user &&
      hasAccessRole(user.roles, [
        UserRoleEnum.agencyMember,
        UserRoleEnum.companyMember,
      ])
    ) {
      isAgency = true;
    }

    const isEmptyArr = size_type.length + location_type.length === 0;

    function disabledDate(current) {
      // Can not select days before today
      return current && current < moment().subtract(1, 'days').endOf('day')
    }

    return (
      <RefinateFiltersFormHolder>
        <Row gutter={16} type="flex" style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {isAgency ? (
              <Row type="flex" align="middle" className="noWrap">
                <span>{t("Client")}</span>
                <Select
                  labelInValue
                  onChange={changeClient}
                  loading={this.props.loadingClients}
                  value={{
                    key: this.props.client.id
                      ? parseInt(this.props.client.id)
                      : t("Select"),
                  }}
                  className="inputSelect"
                >
                  {renderIdentificationClients()}
                </Select>
              </Row>) : null
            }
            {isAgency ? (
              <Row type="flex" align="middle" className="noWrap">
                <span>{t("Mark")}</span>
                <Select
                  labelInValue
                  onChange={changeBrand}
                  loading={this.props.loadingBrands}
                  disabled={this.props.loadingBrands}
                  value={{
                    key: this.props.brand.id
                      ? parseInt(this.props.brand.id)
                      : t("Select"),
                  }}
                  className="inputSelect"
                >
                  {renderIdentificationBrands()}
                </Select>
              </Row>) : null}
            <Row type="flex" align="middle" className="noWrap">
              <span>{t("Dates")}</span>
              <span className="inputSelect">
                <RangePicker
                  value={[moment(date_from), moment(date_to)]}
                  disabledDate={disabledDate}
                  onChange={this.handleDateChange} // PropTypes.func.isRequired,
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              </span>
            </Row>
            <Row type="flex" align="middle" className="noWrap longInput">
              <span>{t("Countries")}</span>
              <span className="inputSelect">
                <Select
                  placeholder={t("Select")}
                  style={{ width: "100%" }}
                  mode="multiple"
                  onChange={changeCountries}
                  value={[...this.props.countries]}
                >
                  {renderIdentificationCountries()}
                </Select>
              </span>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Row type="flex" align="middle" className="noWrap longInput">
              <span>{t("Type")}</span>
              <span className="inputSelect">
                <Select
                  placeholder={t("Select")}
                  style={{ width: "100%" }}
                  mode="multiple"
                  onChange={(e) => this.handleTypeChange(e)}
                  value={[...this.props.location_type]}
                >
                  {this.renderIdentificationType()}
                </Select>
              </span>
            </Row>
            <Row type="flex" align="middle" className="noWrap longInput">
              <span>{t("Sub type")}</span>
              <span className="inputSelect">
                <Select
                  placeholder={t("Select")}
                  style={{ width: "100%" }}
                  mode="multiple"
                  onChange={(e) => this.handleSubTypeChange(e)}
                  value={[...this.props.location_sub_type]}
                >
                  {this.state.list_sub_type}
                </Select>
              </span>
            </Row>
            <Row type="flex" align="middle" style={{ marginBottom: "30px" }}>
              <span style={{ width: "100%" }}>{t("Size")}</span>
              <Checkbox.Group className="inputSelect">
                <Row>
                  <Col sm={24} md={12} lg={12} xl={5}>
                    <Checkbox
                      value="small"
                      checked={isEmptyArr ? false : isSmall}
                      onChange={(e) => this.handleSizeChange(e.target.value)}
                    >
                      {t("Small")}
                    </Checkbox>
                  </Col>
                  <Col sm={24} md={12} lg={12} xl={7}>
                    <Checkbox
                      value="medium"
                      checked={isEmptyArr ? false : isMedium}
                      onChange={(e) => this.handleSizeChange(e.target.value)}
                    >
                      {t("Medium")}
                    </Checkbox>
                  </Col>
                  <Col sm={24} md={12} lg={12} xl={6}>
                    <Checkbox
                      value="big"
                      checked={isEmptyArr ? false : isBig}
                      onChange={(e) => this.handleSizeChange(e.target.value)}
                    >
                      {t("Big")}
                    </Checkbox>
                  </Col>
                  <Col sm={24} md={12} lg={12} xl={6}>
                    <Checkbox
                      value="giant"
                      checked={isEmptyArr ? false : isGiant}
                      onChange={(e) => this.handleSizeChange(e.target.value)}
                    >
                      {t("Giant")}
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Row>
            <Row type="flex" align="middle">
              <Col sm={24} md={24} lg={24} xl={24}>
                <span>{t("Other Filters")}</span>
              </Col>
              <Checkbox
                checked={isCircuit}
                onChange={(e) => setCircuit(e.target.checked ? 1 : 0)}
                className="inputSelect"
              >
                {t("Circuit")}
              </Checkbox>
            </Row>
          </Col>
        </Row>
      </RefinateFiltersFormHolder>
    );
  }
}

export default withTranslation()(RefinateFiltersForm);
