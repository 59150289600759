import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { withTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

class EconomicSocialChartComponent extends Component {

  constructor(props) {
    super(props);
  }
  setting = (data) => {
    const { t } = this.props;
    return {
      labels: [
        `${t("C+")} %`,
        `${t("C")} %`,
        `${t("AB")} %`,
        `${t("E")} %`,
        `${t("D")} %`,
        `${t("NC")} %`,
      ],
      datasets: [
        {
          label: "qqq",
          data: data,
          backgroundColor: [
            "#FFDBD9",
            "#FBF380",
            "#AEC6F0",
            "#FAA097",
            "#82D7B3",
            "#FBB783",
          ],
          borderColor: ["#FFDBD9", "#FBF380", "#AEC6F0", "#FAA097", "#82D7B3", "#FBB783"],
          borderWidth: 1,
        },
      ],
    };
  };

  render() {
    var configChart = this.setting(this.props.data);
    console.log(configChart);
    return <Pie redraw={false} data={configChart} />;
  }
}
export default withTranslation()(EconomicSocialChartComponent);