import React, { Component } from "react";
import ScreenCardHolder from "./Styles/screenCardCheckoutStyle";
import { Button, Icon, Tooltip, Modal, Col } from "antd";
import Slider from "react-slick";
import Dotdotdot from "react-dotdotdot";
import { withTranslation } from 'react-i18next';
import NumberFormat from "./NumberFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole } from "../services/utils"
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import defaultImage from "../images/bg.jpg";
import {getRefinedAudience, belongsToBundle} from "../services/display";
import DeleteBundleModalComponent from "./DeleteBundleModalComponent";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "5px", top: "35%"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "5px", top: "35%", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

class ScreenCardCheckoutComponent extends Component {
  getSizeType(type) {
    if (type === "small") {
      return this.props.t("Small");
    }
    if (type === "medium") {
      return this.props.t("Medium");
    }
    if (type === "big") {
      return this.props.t("Big");
    }
    if (type === "giant") {
      return this.props.t("Giant");
    }
  }

  state = {
    visibleCamTrafficView: false,
    visibleCamScreenView: false,
    visibleAudienceView: false,
    visibleWarningNoAutomateView: false,
    cam_traffic: null,
    cam_display: null,
    displaysAudience: [],
    deleteModalVisible: false,
  };

  setCamlyticsTokens() {
    // Set live view cam and traffic
    var baseLiveViewUrl = "https://cloud.camlytics.com/widget/live_view/";
    // var baseReportUrl = 'https://cloud.camlytics.com/widget/report/';
    if (this.props.display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(this.props.display.camlytics_tokens);
      if (camlyticsTokens.cam_traffic) {
        this.setState({
          cam_traffic: baseLiveViewUrl + camlyticsTokens.cam_traffic,
        });
      }
      if (camlyticsTokens.cam_display) {
        this.setState({
          cam_display: baseLiveViewUrl + camlyticsTokens.cam_display,
        });
      }
    }
  }

  showCamTrafficModal = () => {
    if (this.props.user) {
      this.setCamlyticsTokens();
      this.setState({
        visibleCamTrafficView: true,
      });
    } else {
      this.props.setShouldPromptLogin(true, "");
    }
  };

  okCamTrafficModal = (e) => {
    this.setState({ visibleCamTrafficView: false });
  };

  cancelCamTrafficModal = (e) => {
    this.setState({ visibleCamTrafficView: false });
  };

  showCamScreenModal = () => {
    if (this.props.user) {
      this.setCamlyticsTokens();
      this.setState({
        visibleCamScreenView: true,
      });
    } else {
      this.props.setShouldPromptLogin(true, "");
    }
  };

  okCamScreenModal = (e) => {
    this.setState({ visibleCamScreenView: false });
  };

  cancelCamScreenModal = (e) => {
    this.setState({ visibleCamScreenView: false });
  };

  showAudienceModal = () => {
    if (this.props.user) {
      this.setCamlyticsTokens();
      this.setState({
        visibleAudienceView: true,
      });
    } else {
      this.props.setShouldPromptLogin(true, "");
    }
  };

  okAudienceModal = (e) => {
    this.setState({ visibleAudienceView: false });
  };

  cancelAudienceModal = (e) => {
    this.setState({ visibleAudienceView: false });
  };

  showWarningNoAutomateModal = () => {
    this.setState({ visibleWarningNoAutomateView: true });
  };

  okWarningNoAutomateModal = (e) => {
    this.setState({ visibleWarningNoAutomateView: false });
  };

  cancelWarningNoAutomateModal = (e) => {
    this.setState({ visibleWarningNoAutomateView: false });
  };

  handleDeleteModal = () => {
    this.setState({
      deleteModalVisible: !this.state.deleteModalVisible,
    });
  };

  render() {
    const { t, user, editCampaignActive, currentCampaign , display, cart , listBundle, removeBundlesFromCart} = this.props;
    const { deleteModalVisible } = this.state;
    var matchBundle = belongsToBundle(display, listBundle);

    if (display.camlytics_tokens) {
      var camlyticsTokens = JSON.parse(display.camlytics_tokens);
    }
    let onCart = this.props.cart.find((d) => d.id === display.id);

    var settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <ScreenCardHolder>
        <div className="screenCard">
          <div className="screenContentButton">
            {this.props.display.display_type === "main" &&
            this.props.display.secondary_displays ? (
              <Tooltip
                placement="top"
                title={
                  this.props.display.secondary_displays.length + " pantallas"
                }
              >
                <Button className="circuit" shape="circle" size="small">
                  <Icon type="deployment-unit" theme="outlined" />
                  <span style={{fontSize: "small"}}>{t("circuit")}</span>
                </Button>
              </Tooltip>
            ) : null}
            {camlyticsTokens && camlyticsTokens.cam_traffic ? (
              <Tooltip placement="bottomLeft" title={t("Camera to traffic")}>
                <Button
                  className="screenCardCamTrafficView"
                  shape="circle"
                  size="small"
                  onClick={() => this.showCamTrafficModal()}
                >
                  <Icon type="car" theme="outlined" />
                </Button>
              </Tooltip>
            ) : null}
            <Modal
              title={t("Traffic camera for audience control and measurement")}
              visible={this.state.visibleCamTrafficView}
              onOk={this.okCamTrafficModal}
              onCancel={this.cancelCamTrafficModal}
            >
              <iframe
                id="live-view-traffic"
                src={this.state.cam_traffic}
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="300px"
                title={t("Traffic camera for audience control and measurement")}
              ></iframe>
            </Modal>
            {camlyticsTokens && camlyticsTokens.cam_display ? (
              <Tooltip
                placement="bottomLeft"
                title={t("Camera for certification")}
              >
                <Button
                  className="screenCardCamCertification"
                  shape="circle"
                  size="small"
                  onClick={() => this.showCamScreenModal()}
                >
                  <Icon type="video-camera" theme="outlined" />
                </Button>
              </Tooltip>
            ) : null}
            <Modal
              title={t("Camera looking at screen for certification")}
              visible={this.state.visibleCamScreenView}
              onOk={this.okCamScreenModal}
              onCancel={this.cancelCamScreenModal}
            >
              <iframe
                id="live-view-traffic"
                src={this.state.cam_display}
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="300px"
                title={t("Camera looking at screen for certification")}
              ></iframe>
            </Modal>
            {(this.props.display.has_external_audience_data ||
              getRefinedAudience(user, editCampaignActive ? ( currentCampaign ? currentCampaign.programmatic_provider_id: null) : null)) &&
              <Tooltip placement="bottomLeft" title={t("Audience detail")}>
                <Button
                  className="screenCardAudit"
                  shape="circle"
                  size="small"
                  onClick={() => this.showAudienceModal()}
                >
                  <Icon type="audit" theme="outlined" />
                </Button>
              </Tooltip>
            }
            {this.props.display.mirror_screens_count > 1 ? (
              <Tooltip placement="bottomLeft" title={`${t("Multiple faces screen")} (${this.props.display.mirror_screens_count})`}>
                <Button className="doubleScreen" shape="circle" size="small">
                  <FontAwesomeIcon
                    icon={faClone}
                    size="20x"
                    className="iconTranslate"
                  />
                </Button>
              </Tooltip>
            ) : null}
          </div>
          {/* AUDIENCE MODAL */}
          <Modal
            title={t("Audience")}
            visible={this.state.visibleAudienceView}
            onOk={this.okAudienceModal}
            onCancel={this.cancelAudienceModal}
            width={1000}
          >
            <AudienceSummaryComponentGeneral
              t={t}
              display={this.props.display}
              user={user}
              campaign={ editCampaignActive ? currentCampaign : null}
            />
          </Modal>
          {this.props.display.use_cms === 0 ? (
            <Tooltip
              placement="bottomLeft"
              title={t("Non-automated screen. Click to see detail")}
            >
              <Button
                className="screenWarningNoAutomateView"
                shape="circle"
                size="small"
                onClick={() => this.showWarningNoAutomateModal()}
              >
                <Icon
                  type="warning"
                  theme="outlined"
                  style={{ color: "#ffc107" }}
                />
              </Button>
            </Tooltip>
          ) : null}

          {/* OTHER MODAL */}
          <Modal
            title={t("Non-automated screen")}
            visible={this.state.visibleWarningNoAutomateView}
            onOk={this.okWarningNoAutomateModal}
            onCancel={this.cancelWarningNoAutomateModal}
          >
            <p style={{ margin: "25px" }}>
              {t(
                "This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected."
              )}
            </p>
          </Modal>
          <Slider {...settings}>
            {this.props.display.pictures.length === 0 ? (
              <img
                src={defaultImage}
                alt=""
                className="screenCardImage"
              />
            ) : (
              this.props.display.pictures &&
                this.props.display.pictures.map((p, index) => (
                  <img
                    key="{index}"
                    src={
                      p.url
                        .split(".")
                        .slice(0, -1)
                        .join(".") + "-thumb.jpg"
                    }
                    className="screenCardImage"
                    alt=""
                  />
                ))
            )}
          </Slider>
          <div
            className={
              this.props.display.display_type === "main"
                ? this.props.isCurrentDisplay
                  ? "screenCardContentCurrent"
                  : "screenCardContentCircuit"
                : this.props.isCurrentDisplay
                ? "screenCardContentCurrent"
                : "screenCardContent"
            }
          >
            <div className="screenCardPrice">
              <NumberFormat
                value={
                  this.props.programmatic === "programmatic"
                    ? Math.round(
                        (this.props.display.smart_campaign_cpm / 1000) * 100
                      ) / 100
                    : this.props.display.price_per_day
                }
                currency={this.props.display.price_currency}
              />
              {this.props.programmatic === "programmatic"
                ? " / " + t("Print")
                : " / " + t("Day")}
            </div>
            <Tooltip
              placement="top"
              title={
                user &&
                hasAccessRole(user.roles, [
                  UserRoleEnum.superModeratorAgency,
                  UserRoleEnum.superModeratorMediaCompany,
                ])
                  ? " (" + this.props.display.name + this.props.display.id + ")"
                  : " (" + this.props.display.name + ")"
              }
            >
              <div className="screenCardTitle">
                <Dotdotdot clamp={1}>{this.props.display.name}</Dotdotdot>
              </div>
            </Tooltip>
            <div className="screenCardInfo">
              <span style={{ textTransform: "capitalize" }}>
                {t("screen")}{" "}
              </span>
              <b>{this.getSizeType(this.props.display.size_type)} </b>
            </div>
            <DeleteBundleModalComponent
              deleteModalVisible={deleteModalVisible}
              matchBundle={matchBundle}
              handleDeleteModal={this.handleDeleteModal}
              cart={cart}
              listBundle={listBundle}
              removeBundlesFromCart={removeBundlesFromCart}
            />
            <Tooltip
                title={
                  this.props.editCampaign.payment_status === "approved" && this.props.editCampaignActive ?
                    t("Ads cannot be edited in approved campaigns")
                  : matchBundle ?
                    t("When deleting this screen, the packages to which it belongs, as well as the screens associated with those packages, will be automatically removed from the cart."):
                    null
                } >
              <Button
                disabled={
                  (this.props.editCampaign.payment_status === "approved" &&
                    this.props.editCampaignActive) ||
                  false
                }
                className={onCart ? "screenCardCloseButton" : "screenCardButton"}
                shape="circle"
                icon={onCart ? "close" : "plus"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onCart) {
                    if (matchBundle){
                      this.handleDeleteModal()
                    } else {
                      this.props.removeDisplayFromCart(display.id);
                    }
                  } else {
                    this.props.addDisplayToCart(this.props.display);
                  }
                  onCart = !onCart;
                }}
              />
            </Tooltip>
          </div>
        </div>
      </ScreenCardHolder>
    );
  }
}
export default withTranslation()(ScreenCardCheckoutComponent);
