import styled from "styled-components";

const RegionLangHolder = styled.div`
  margin-right: 10px;
  padding: 5px 8px 5px 8px;
  border-radius: 8px;
  background-color: #F1F6FB;
  
  
  .regionButton{
    border-color: "transparent";
  }

  }
`;

export default RegionLangHolder;
