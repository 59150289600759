import React from "react";
import { Modal, Descriptions, Badge } from 'antd';
import moment from "moment";
import CampaingDetailHolder from "./Styles/campaignDetailCardStyle";
import { SeparateNumberFormat } from "../services/utils";

class CardDetailModal extends React.Component {

  render() {

    const { visible, onOk, onCancel, title, data,  okText, cancelText, t , i18n} = this.props;

    function StatusCampaing(props) {
      if(moment().isAfter(props.end_date) && props.payment_status ==='approved' && props.status==='ready'){
        return (<Badge color="lime" text={t('Finalized')} />)
      }else{
        if (props.status === 'pending' ){
          return (<Badge status="warning" text={t('Pending')} />)
        }
        else if(props.status === 'approved' ){
          return (<Badge status="processing" text={t('Approved')} />)
        }
        else if(props.status === 'ready' ){
          return(<Badge status="success" text={t('Ready')} />)
        }
        
        else if(props.status === 'cancelled' ){
          return (<Badge status="default" text={t('Cancelled')} />)
        }
        else if(props.status === 'rejected' ){
          return (<Badge status="error" text={t('Rejected')} />)
        }else{
          return null
        }
      }
   }

   const campaingDays = - moment(data.start_date).diff(data.end_date, "days")+1;

   const promisedShows = data.contents_displays.reduce(
    (acc, display) => {
      return acc + display.promised_shows;
    },
    0
  );

    return (
        <Modal
          bodyStyle={{ padding: 20 }}
          title={title}
          width={"80%"}
          visible={visible}
          okText={okText}
          cancelText={cancelText}
          onOk={onOk}
          onCancel={onCancel}
        >
         <CampaingDetailHolder>
            <Descriptions bordered>
              <Descriptions.Item label={t("Campaign created by")+":"} span={2}>
                {`${data.creator === null ? '--' : data.creator.first_name } ${data.creator === null ? '--' : data.creator.last_name}`}
              </Descriptions.Item>
              <Descriptions.Item label={t("Exhibition date")+":"} span={2}>
                {i18n.language === 'en' ?
                    (
                      moment(data.start_date).format("MM/DD/YYYY") + " - "+
                      moment(data.end_date).format("MM/DD/YYYY")
                    ) : (
                      moment(data.start_date).format("DD/MM/YYYY")  + " - " +
                      moment(data.end_date).format("DD/MM/YYYY")
                    )
                  }
              </Descriptions.Item>
              <Descriptions.Item label={t("for a total amount of")+":"} span={2}>
                {`$${data.total_price_with_profit}`}
              </Descriptions.Item>
              <Descriptions.Item label={t("Campaign status")+":"} span={2}>
                <StatusCampaing
                  status = {data.status}
                  payment_status = {data.payment_status}
                  end_date = {data.end_date}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('Duration')+":"} span={2}>
                {campaingDays > 0 ? campaingDays : 0} {t('Day')}
                {campaingDays === 1 ? "" : "s"}
              </Descriptions.Item>
              <Descriptions.Item label={data.smart_campaign ? t('Purchased impressions') : t('Promised impressions')} span={2}>
                {SeparateNumberFormat(promisedShows)}
              </Descriptions.Item>
              {data.description &&
                <Descriptions.Item label={t("Campaign extra information")+":"} className="campaignItem" span={4}>
                    <span className="campaignItem">
                      {data.description}
                    </span>
                </Descriptions.Item>
              }
            </Descriptions>
         </CampaingDetailHolder>
        </Modal>
    );
  }
}

export default CardDetailModal;