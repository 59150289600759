import React from "react";
import { Modal, Icon } from 'antd';

class OptionModal extends React.Component {


  render() {
    const { visible, onOk, onCancel, title, body,  okText, cancelText } = this.props;

    return (
      <div>
        <Modal
          bodyStyle={{ padding: 20 }}
          title={title}
          visible={visible}
          okText={okText}
          cancelText={cancelText}
          onOk={onOk}
          onCancel={onCancel}
        >
           
          <p> <Icon type="exclamation-circle" /> {body}</p>
        </Modal>
      </div>
    );
  }
}

export default OptionModal;