import { connect } from 'react-redux'
import ScreenComponent from '../components/ScreenComponent'
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display, userAccount }) => ({
  displays: display.displays,
  currentDisplay: display.currentDisplay,
  cart: display.cart,
  fetching: display.fetching,
  editCampaignActive: display.editCampaignActive,
  editCampaign: display.editCampaign,
  fetchingDisplayInfo: display.fetchingDisplayInfo,
  programmatic: display.programmatic,
  client: display.client,
  errors: display.errors,
  audienceDatsWhy: display.audienceDatsWhy,
  loadingAudienceDatsWhy: display.loadingAudienceDatsWhy,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  userCurrency: userAccount.currency,
  initialCurrency: display.currency,
  editCampaignActive: display.editCampaignActive,
  currentCampaign: display.currentCampaign,
  user: userAccount.user,
  external_dsp_id: display.external_dsp_id,
  date_from: display.date_from,
  date_to: display.date_to,
  cart: display.cart,
  listBundle: display.listBundle,
});

const mapStateToDispatch = (dispatch) => ({
  getDisplayInfoRequest: (id, clientId, currency, invoice_issuing_country, external_dsp_id) =>
    dispatch(DisplayActions.getDisplayInfoRequest(id, clientId, currency, invoice_issuing_country, external_dsp_id)),
  addDisplayToCart: (display) =>
    dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplayFromCart: (displayId) =>
    dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  getAudienceDatsWhyRequest: (id) =>
    dispatch(DisplayActions.getAudienceDatsWhyRequest(id)),
  setAudienceDatsWhy: (data) =>
    dispatch(DisplayActions.setAudienceDatsWhy(data)),
  addListDisplayToCart: (displays) =>
    dispatch(DisplayActions.addListDisplayToCart(displays)),
  getDisplaysCart: params => dispatch(DisplayActions.getDisplaysCartRequest(params)),
  removeDisplaysCart: () => dispatch(DisplayActions.removeDisplaysCart()),
  cleanAudience: () => dispatch(DisplayActions.cleanAudience()),
});

export default connect(mapStateToProps, mapStateToDispatch)(ScreenComponent)
