import { connect } from 'react-redux'
import ScreenCardCheckoutComponent from "../components/ScreenCardCheckoutComponent";
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from '../redux/UserAccountRedux'

const mapStateToProps = ({ display, userAccount}) => ({
  user: userAccount.user,
  cart: display.cart,
  clients: display.clients,
  client: display.client,
  programmatic: display.programmatic,
  date_from: display.date_from,
  date_to: display.date_to,
  editCampaignActive: display.editCampaignActive,
  editCampaign: display.editCampaign,
  currency: userAccount.currency,
  editCampaignActive: display.editCampaignActive,
  currentCampaign: display.currentCampaign,
  listBundle: display.listBundle,
})

const mapStateToDispatch = (dispatch) => ({
  addDisplayToCart: (display) => dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  getAudience: (data) => dispatch(DisplayActions.getAudienceRequest(data)),
  removeBundlesFromCart: (displayIds, bundleId) => dispatch(DisplayActions.removeBundlesFromCart(displayIds, bundleId)),
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ScreenCardCheckoutComponent);
