import { put, call, delay } from 'redux-saga/effects'
import { get } from 'lodash'
import DisplayActions from '../redux/DisplayRedux'
import { history } from '../redux';


export function * getDisplaysCartRequest (api, action) {
  const { params } = action

  const resp = yield call(api.getDisplays, params)

  if (resp.ok) {
    const displays = resp.data.data
    yield put(DisplayActions.getDisplaysCartSuccess(displays))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getDisplaysCartFailure(errors))
  }
}

export function * getDisplaysRequest (api, action) {
  const { params } = action

  const resp = yield call(api.getDisplays, params)

  if (resp.ok) {
    const displays = resp.data.data

    yield put(DisplayActions.getDisplaysSuccess(displays, resp.data.per_page, resp.data.total))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getDisplaysFailure(errors))
  }
}

export function * getDisplayInfoRequest (api, action) {
  const { id, clientId , currency, invoice_issuing_country, external_dsp_id} = action

  const resp = yield call(api.getDisplayInfo, id, clientId, currency, invoice_issuing_country, external_dsp_id)

  if (resp.ok) {
    const display = resp.data

    yield put(DisplayActions.getDisplayInfoSuccess(display))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getDisplayInfoFailure(errors))
  }
}

export function * getDisplaysInfoRequest (api, action) {
  const { params } = action

  const resp = yield call(api.getDisplays, params)

  if (resp.ok) {

    const displays = resp.data.data
    yield put(DisplayActions.getDisplaysInfoSuccess(displays))

  } else {

    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getDisplaysInfoRequestFailure(errors))
  }
}

export function * getExcelDisplaysRequest (api, action) {
  const { params } = action
  const resp = yield call(api.getExcelDisplays, params)

  if (resp.ok) {
    const excel = resp.data
    yield put(DisplayActions.getExcelDisplaysSuccess(excel))

  } else {
    let errors
    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getExcelDisplaysFailure(errors))
  }
}

export function * updateDefaultContentRequest (api, action) {
  const { id } = action

  const resp = yield call(api.getContent, id)

  if (resp.ok) {
    const content = resp.data

    yield put(DisplayActions.updateDefaultContentSuccess(content))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.updateDefaultContentFailure(errors))
  }
}

export function * updateCustomContentRequest (api, action) {
  const { id, displayId, relationId, typePreview } = action

  let resp = yield call(api.getContent, id)

  if (resp.ok) {
    const content = resp.data

    yield put(DisplayActions.updateCustomContentSuccess(content, displayId, relationId, typePreview))

    let keepUpdating = !content.ready
    while (keepUpdating) {
      resp = yield call(api.getContent, id)
      if (get(resp, 'data.ready') || !resp.ok) {
        const content = resp.data
        yield put(DisplayActions.updateCustomContentSuccess(content, displayId, relationId, typePreview))
        return
      } else {
        yield delay(3000)
      }
    }

  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.updateCustomContentFailure(errors))
  }
}

export function * updateContentCampaignRequest (api, action ) {
  const { content, relation, campaignId } = action

  if (relation.belong_to_smart_campaign) {
    var newRelation = {
      "display_id": relation.display_id,
      "content_id": content.id,
      "fill_screen": relation.fill_screen,
      "promised_shows": relation.promised_shows,
      // "rules": relation.rules
    }
  } else {
    var newRelation = {
      "display_id": relation.display_id,
      "content_id": content.id,
      "fill_screen": relation.fill_screen,
      // "rules": relation.rules
    }
  }

  let resp = yield call(api.getContent, content.id)

  if (resp.data.type === 'image') {
    let response = yield call(api.updateCampaignContentDisplay, relation.id, newRelation, campaignId)
    if (response.ok) {
      yield put(DisplayActions.updateContentCampaignSuccess(resp.data, relation.id))
    } else {
      let errors
      if (response.problem === 'CLIENT_ERROR') {
        errors = [response.data.message]
      }
      else {
        errors = [response.problem]
      }
      yield put(DisplayActions.updateContentCampaignFailure(errors))
    }
  } else if (resp.data.type === 'video') {
    if (resp.data.ready) {
      let response = yield call(api.updateCampaignContentDisplay, relation.id, newRelation, campaignId)
      if (response.ok) {
        yield put(DisplayActions.updateContentCampaignSuccess(resp.data, relation.id))
      } else {
        let errors
        if (response.problem === 'CLIENT_ERROR') {
          errors = [response.data.message]
        }
        else {
          errors = [response.problem]
        }
        yield put(DisplayActions.updateContentCampaignFailure(errors))
      }
    } else {
      yield delay(3000)
    }
  }
}

export function * uploadFileRequest (api, action) {
  const { file } = action

  const resp = yield call(api.uploadFile, file)
  if (resp.ok) {
    const data = { resp }

    yield put(DisplayActions.uploadFileSuccess(data))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.uploadFileFailure(errors))
  }
}

export function * createCampaignRequest (api, action) {
  const { params } = action

  const resp = yield call(api.createCampaign, params)
  if (resp.ok) {
    const campaign = resp.data

    yield put(DisplayActions.createCampaignSuccess(campaign))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.error]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.createCampaignFailure(errors))
  }
}

export function * editCampaignRequest (api, action) {
  const { campaignId, params } = action

  const resp = yield call(api.editCampaign, campaignId, params)
  if (resp.ok) {
    const campaign = resp.data

    yield put(DisplayActions.editCampaignSuccess(campaign))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data]
    }
    else {
      errors = [resp.problem]
    }
    yield put(DisplayActions.editCampaignFailure(errors))
  }
}

export function * archiveCampaignRequest (api, action) {
  const { campaignId, params} = action

  const resp = yield call(api.editCampaign, campaignId, params)
  if (resp.ok) {
    const campaign = resp.data
    yield put(DisplayActions.archiveCampaignSuccess(campaign.id))

  } else {
    let errors
    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data]
    }
    else {
      errors = [resp.problem]
    }
    yield put(DisplayActions.archiveCampaignFailure(errors))
  }
}

export function * getCampaignsRequest (api, action) {
  const { params } = action

  const resp = yield call(api.getCampaigns, params)
  if (resp.ok) {
    const campaigns = resp.data.data

    yield put(DisplayActions.getCampaignsSuccess(campaigns, resp.data.recordsFiltered))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getCampaignsFailure(errors))
  }
}

export function * getCampaignRequest (api, action) {
  const { id } = action

  const resp = yield call(api.getCampaign, id)
  if (resp.ok) {
    const campaign = resp.data

    yield put(DisplayActions.getCampaignSuccess(campaign))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getCampaignFailure(errors))
  }
}

export function * getClientsRequest (api, action) {
  const { userId } = action

  const resp = yield call(api.getClients, userId)
  if (resp.ok) {
    const clients = resp.data

    yield put(DisplayActions.getClientsSuccess(clients))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getClientsFailure(errors))
  }
}

export function * getExternalDspsRequest (api) {
  const resp = yield call(api.getExternalDsps)
  if (resp.ok) {
    yield put(DisplayActions.getExternalDspsSuccess(resp.data))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    yield put(DisplayActions.getExternalDspsFailure(errors))
  }
}

export function * deleteCampaignRequest (api, action) {
  const { campaignId } = action

  const resp = yield call(api.deleteCampaign, campaignId)
  if (resp.ok) {
    yield put(DisplayActions.deleteCampaignSuccess(resp.data, campaignId))
  } else {
    yield put(DisplayActions.deleteCampaignFailure(resp.data))
  }
}

export function * createReplicateCampaignRequest (api, action) {
  const { campaignId, params } = action

  const resp = yield call(api.createReplicateCampaing, campaignId, params)
  if (resp.ok) {
    const campaign = resp.data

    yield put(DisplayActions.createReplicateCampaignSuccess(campaign.id))

  } else {
    yield put(DisplayActions.createReplicateCampaignFailure(resp.data))
  }
}

export function * requestSpecialCpmRequest (api, action) {
  const { displayId, requestedPrice, currency } = action;

  const resp = yield call(api.requestSpecialCpm, displayId, requestedPrice, currency)
  if (resp.ok) {
    yield put(DisplayActions.requestSpecialCpmSuccess())
  } else {
    yield put(DisplayActions.requestSpecialCpmFailure(resp.data))
  }
}

export function * requestSpecialPriceRequest (api, action) {
  const { displayId, requestedPrice, currency } = action;

  const resp = yield call(api.requestSpecialPrice, displayId, requestedPrice, currency)
  if (resp.ok) {
    yield put(DisplayActions.requestSpecialPriceSuccess())
  } else {
    yield put(DisplayActions.requestSpecialPriceFailure(resp.data))
  }
}


export function * getBrandsRequest (api, action) {
  const { clientId } = action

  const resp = yield call(api.getBrands, clientId)
  if (resp.ok) {
    const brands = resp.data

    yield put(DisplayActions.getBrandsSuccess(brands))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getBrandsFailure(errors))
  }
}

export function * getQuotesRequest (api, action) {
  const { userId , params} = action

  const resp = yield call(api.getQuotes, userId, params)
  if (resp.ok) {
    const quotes = resp.data

    yield put(DisplayActions.getQuotesSuccess(quotes.reverse()))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getQuotesFailure(errors))
  }
}

export function * setArchivedQuoteRequest (api, action) {
  const { quoteId, archived } = action

  const resp = yield call(api.setArchivedQuote, quoteId, archived)

  if (resp.ok) {
    yield put(DisplayActions.setArchivedQuoteSuccess(resp.data))
  } else {
    yield put(DisplayActions.setArchivedQuoteFailure(resp.data))
  }
}

export function * getQuoteRequest (api, action) {
  const { token, cart } = action

  const resp = yield call(api.getQuote, token)

  if (resp.ok) {
    const quote = resp.data

    for (var i = 0; i < cart.length; i++) {
      yield put(DisplayActions.removeDisplayFromCart(cart[i].id))
    }
    for (var i = 0; i < quote.displays.length; i++) {
      yield put(DisplayActions.addDisplayToCart(quote.displays[i]))
    }
    let id_dsp = quote.external_dsp ? quote.external_dsp.id : null;
    history.replace(`/map?currency=${quote.currency}&external_dsp_id=${id_dsp}&date_from=${quote.from_date}&date_to=${quote.to_date}&lat_ne=${quote.displays[0].latitude}&lat_sw=${quote.displays[0].latitude}&lng_ne=${quote.displays[0].longitude}&lng_sw=${quote.displays[0].longitude}&quote_profit=${quote.profit}&page=1&price_max=10000&price_min=0&shows_per_day_min=1&is_quote=true&is_circuit=0`);

    yield put(DisplayActions.getQuoteSuccess(quote))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getQuoteFailure(errors))
  }
}

export function * createQuoteRequest (api, action) {
  const { quoteData } = action

  const resp = yield call(api.createQuote, quoteData)
  if (resp.ok) {
    yield put(DisplayActions.createQuoteSuccess(resp.data))
  } else {
    yield put(DisplayActions.createQuoteFailure(resp.data))
  }
}

export function * editQuoteRequest (api, action) {
  const { quoteId, quoteData } = action
  const resp = yield call(api.editQuote, quoteId, quoteData)
  if (resp.ok) {
    yield put(DisplayActions.editQuoteSuccess(resp.data))
  } else {
    yield put(DisplayActions.editQuoteFailure(resp.data))
  }
}

export function * deleteQuoteRequest (api, action) {
  const { quoteId } = action

  const resp = yield call(api.deleteQuote, quoteId)
  if (resp.ok) {
    yield put(DisplayActions.deleteQuoteSuccess(resp.data))
  } else {
    yield put(DisplayActions.deleteQuoteFailure(resp.data))
  }
}

export function * createCouponRequest (api, action) {
  const { campaignId , code } = action

  const resp = yield call(api.createCoupon, campaignId, code)
  if (resp.ok) {
    const couponData = resp.data

    yield put(DisplayActions.createCouponSuccess(couponData))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }
    yield put(DisplayActions.createCouponFailure(errors))
  }
}

export function * deleteCouponRequest (api, action) {
  const { campaignId , couponId } = action

  const resp = yield call(api.deleteCoupon, campaignId, couponId)
  if (resp.ok) {
    const couponData = resp.data

    yield put(DisplayActions.deleteCouponSuccess(couponData))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }
    yield put(DisplayActions.deleteCouponFailure(errors))
  }
}

// export function * getMercadoPagoPaymentMethodsRequest (api, action) {
//   const resp = yield call(api.getMercadoPagoPaymentMethods)

//   if (resp.ok) {
//     yield put(DisplayActions.getMercadoPagoPaymentMethodsSuccess(resp.data))
//   } else {
//     yield put(DisplayActions.getMercadoPagoPaymentMethodsFailure(resp.data))
//   }
// }

export function * createPaymentRequest (api, action) {
  const { campaignId, paymentData } = action

  const resp = yield call(api.createPayment, campaignId, paymentData)

  if (resp.ok) {
    yield put(DisplayActions.createPaymentSuccess(resp.data))
  } else {
    yield put(DisplayActions.createPaymentFailure(resp.data))
  }
}

export function * getCampaignPaymentsRequest (api, action) {
  const { campaignId } = action

  const resp = yield call(api.getCampaignPayments, campaignId)

  if (resp.ok) {
    yield put(DisplayActions.getCampaignPaymentsSuccess(resp.data))
  } else {
    yield put(DisplayActions.getCampaignPaymentsFailure(resp.data))
  }
}

export function * getPaymentRequest (api, action) {
  const { paymentId } = action

  const resp = yield call(api.getPayment, paymentId)

  if (resp.ok) {
    yield put(DisplayActions.getPaymentSuccess(resp.data))
  } else {
    yield put(DisplayActions.getPaymentFailure(resp.data))
  }
}

export function * deleteCampaignPaymentRequest (api, action) {
  const { paymentId } = action

  const resp = yield call(api.deleteCampaignPayment, paymentId)

  if (resp.ok) {
    yield put(DisplayActions.deletePaymentSuccess(resp.data))
  } else {
    yield put(DisplayActions.deletePaymentFailure(resp.data))
  }
}

export function * getMpInitPointRequest (api, action) {
  const { campaignId, paymentData } = action

  const resp = yield call(api.getMpInitPoint, campaignId, paymentData)

  if (resp.ok) {
    yield put(DisplayActions.getMpInitPointSuccess(resp.data))
  } else {
    yield put(DisplayActions.getMpInitPointFailure(resp.data))
  }
}

export function * getReportRequest (api, action) {
  const { reportData } = action

  const resp = yield call(api.getReport, reportData)

  if (resp.ok) {
    yield put(DisplayActions.getReportSuccess(resp.data))
  } else {
    yield put(DisplayActions.getReportFailure(resp.data))
  }
}

export function * getProposalRequest (api, action) {
  const { proposalData } = action

  const resp = yield call(api.getProposal, proposalData)

  if (resp.ok) {
    yield put(DisplayActions.getProposalSuccess(resp.data))
  } else {
    yield put(DisplayActions.getProposalFailure(resp.data))
  }
}

export function * getSingleReportRequest (api, action) {
  const { params } = action

  const resp = yield call(api.getSingleReport, params)

  if (resp.ok) {
    yield put(DisplayActions.getSingleReportSuccess(resp.data))
  } else {
    yield put(DisplayActions.getSingleReportFailure(resp.data))
  }
}

export function * getLastBillingInfoRequest (api, action) {
  const { userId } = action

  const resp = yield call(api.getLastBillingInfo, userId)

  if (resp.ok) {
    yield put(DisplayActions.getLastBillingInfoSuccess(resp.data))
  } else {
    yield put(DisplayActions.getLastBillingInfoFailure(resp.data))
  }
}

export function * getAudienceRequest (api, action) {
  const { data } = action

  const resp = yield call(api.getAudience, data)
  if (resp.ok) {
    const audience = resp.data

    yield put(DisplayActions.getAudienceSuccess(audience))
  } else {
    let errors

    if (resp.problem === 'CLIENT_ERROR') {
      errors = [resp.data.message]
    }
    else {
      errors = [resp.problem]
    }

    yield put(DisplayActions.getAudienceFailure(errors))
  }
}

export function* getAudienceDatsWhyRequest(api, action) {
  let { data } = action;
  // TO DO: prepared data when is more than one display, because {id: data.display_ids} hope is one id
  data = {"display_ids": data}
  const resp = yield call(api.getAudienceDatsWhy, data);

  if (resp.ok) {
    const audience = resp.data;
    yield put(DisplayActions.getAudienceDatsWhySuccess({id: data.display_ids , data: audience}));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data.message];
    } else {
      errors = [resp.problem];
    }
    yield put(DisplayActions.getAudienceDatsWhyFailure(errors, {id: data , data: null}));
  }
}

export function* createPurchaseOrdersRequest(api, action) {
  const { companyId, campaignId } = action

  const resp = yield call(api.createPurchaseOrders, companyId, campaignId)

  if (resp.ok) {
    yield put(DisplayActions.createPurchaseOrdersSuccess(resp.data))
  } else {
    yield put(DisplayActions.createPurchaseOrdersFailure("Error"))
  }
}

export function* getCurrencyExchangeRateRequest(api) {

  const resp = yield call(api.getCurrencyExchangeRate)

  if (resp.ok) {
    yield put(DisplayActions.getCurrencyExchangeRateSuccess(resp.data))
  } else {
    yield put(DisplayActions.getCurrencyExchangeRateFailure("Error"))
  }
}

export function* getConversionRatesRequest(api) {
  const resp = yield call(api.getConversionRates)

  if (resp.ok) {
    yield put(DisplayActions.getConversionRatesSuccess(resp.data))
  } else {
    yield put(DisplayActions.getConversionRatesFailure("Error"))
  }
}

export function* getCategoriesRequest(api) {
  const resp = yield call(api.getCategories)

  if (resp.ok) {
    yield put(DisplayActions.getCategoriesSuccess(resp.data))
  } else {
    yield put(DisplayActions.getCategoriesFailure("Error"))
  }
}