import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tabs } from 'antd';
import { withTranslation } from "react-i18next";
import { metabaseUrl } from "../services/utils";
import  chartsEnum from "../constants/AudienceChartEnum";
import {getRefinedAudience} from "../services/display";
import  AudienceProviderInfo  from "./AudienceProviderInfo";

const { TabPane } = Tabs;

class AudienceSummaryComponentGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'general',
    };
  }

  componentDidMount() {}

  getTypeAudience = (noRefined, refined) => {
    const { user, campaign} = this.props;
    let audienceRefined = getRefinedAudience( user, campaign ? campaign.programmatic_provider_id: null)
    return audienceRefined ? refined : noRefined;
  }

  handleModeChange = e => {
    const mode = e.target.value;
    this.setState({ mode });
  };
  render() {
    const { t, display, user} = this.props;
    return (
      <Tabs defaultActiveKey="1">
          <TabPane tab={t("General")} key="1">
            <AudienceProviderInfo displays={[display]} user={user}/>
            <iframe
                    id="audience-general"
                    title="audience-genera"
                    src={
                      `${metabaseUrl}${this.getTypeAudience(chartsEnum.audienceGreneral, chartsEnum.audienceGreneralRefined)}?displayid=${display.id}#hide_parameters=displayid`
                    }
                    frameBorder="0"
                    scrolling="yes"
                    width="100%"
                    height="600px"
            ></iframe>
          </TabPane>
          <TabPane tab={t("By day of week")} key="2">
            <AudienceProviderInfo displays={[display]} user={user}/>
            <iframe
                  id="audience-by-day-of-week"
                  title="audience-by-day-of-week"
                  src={
                    `${metabaseUrl}${this.getTypeAudience(chartsEnum.audienceGreneralPerday, chartsEnum.audienceGreneralPerdayRefined)}?displayid=${display.id}#hide_parameters=displayid`
                  }
                  frameBorder="0"
                  scrolling="yes"
                  width="100%"
                  height="600px"
              ></iframe>
          </TabPane>
      </Tabs>
    );
  }
}

AudienceSummaryComponentGeneral.contextTypes = {
  router: PropTypes.object,
};

const Extended = withTranslation()(AudienceSummaryComponentGeneral);
Extended.static = AudienceSummaryComponentGeneral.static;

export default AudienceSummaryComponentGeneral;
