import { connect } from 'react-redux'
import CampaignComponent from '../components/CampaignComponent'
import DisplayActions from '../redux/DisplayRedux'
import UserAccountActions from "../redux/UserAccountRedux";

const mapStateToProps = ({ userAccount, display }) => ({
  user: userAccount.user,
  campaigns: display.campaigns,
  currentCampaign: display.currentCampaign,
  currentDisplay: display.currentDisplay,
  payments: display.payments,
  errors: display.errors,
  fetchingPayments: display.fetchingPayments,
  deletingPayment: display.deletingPayment,
  fetchingCampaign: display.fetchingCampaign,
  loadingReport: display.loadingReport,
  reportToken: display.reportToken,
  loadingSingleReport: display.loadingSingleReport,
  reportSingleData: display.reportSingleData,
  editCampaignActive: display.editCampaignActive,
  campaignName: display.campaignName,
  cart: display.cart,
  fetchingDisplayInfo: display.fetchingDisplayInfo,
  fetchingDisplaysInfo: display.fetchingDisplaysInfo,
  proposalToken: display.proposalToken,
  loadingProposal: display.loadingProposal,
  invoiceIssuingCountry: userAccount.invoiceIssuingCountry,
  external_dsp_id: display.external_dsp_id,
  isSuperUser: userAccount.isSuperUser,
  categories: display.categories,
  editingCampaign: display.editingCampaign,
})

const mapStateToDispatch = (dispatch) => ({
  getCampaign: (id) => dispatch(DisplayActions.getCampaignRequest(id)),
  getCampaignPayments: (id) => dispatch(DisplayActions.getCampaignPaymentsRequest(id)),
  deletePayment: (id) => dispatch(DisplayActions.deletePaymentRequest(id)),
  getProposalRequest: (proposalData) => dispatch(DisplayActions.getProposalRequest(proposalData)),
  // getDisplayInfo: (id) => dispatch(DisplayActions.getDisplayInfoRequest(id)),
  getReportRequest: (reportData) => dispatch(DisplayActions.getReportRequest(reportData)),
  getSingleReportRequest: (reportSingleData) => dispatch(DisplayActions.getSingleReportRequest(reportSingleData)),
  setDefaultContent: content => dispatch(DisplayActions.setDefaultContent(content)),
  clearCampaignData: id => dispatch(DisplayActions.clearCampaignData(id)),
  addDisplayToCart: (display) => dispatch(DisplayActions.addDisplayToCart(display)),
  removeDisplaysCart: (id) => dispatch(DisplayActions.removeDisplaysCart(id)),
  removeDisplayFromCart: (displayId) => dispatch(DisplayActions.removeDisplayFromCart(displayId)),
  addCustomContentFromCampaign: (relationId, indice_relation, displayId, fill_screen, isContentMixed, promised_shows, length, content, rules, subsidized_price, promised_shows_subsidized, content_name ) => dispatch(DisplayActions.addCustomContentFromCampaign(relationId, indice_relation, displayId, fill_screen, isContentMixed, promised_shows, length, content, rules, subsidized_price, promised_shows_subsidized, content_name)),
  editCampaignActive: (campaign) => dispatch(DisplayActions.editCampaignActive(campaign)),
  editCampaignInactive: (campaign) => dispatch(DisplayActions.editCampaignInactive(campaign)),
  setCampaignName: campaignName => dispatch(DisplayActions.setCampaignName(campaignName)),
  updateCustomContentRequestActive: (id, displayId, relationId, active) => dispatch(DisplayActions.updateCustomContentRequest(id, displayId, relationId, null, active)),
  setBudget: (amountBudget) => dispatch(DisplayActions.setBudget(amountBudget)),
  setDates: (from, to) => dispatch(DisplayActions.setDates(from, to)),
  setProgrammatic: (programmatic) => dispatch(DisplayActions.setProgrammatic(programmatic)),
  getDisplayInfoRequest: (id, clientId, currency, invoice_issuing_country, external_dsp_id) => dispatch(DisplayActions.getDisplayInfoRequest(id, clientId, currency, invoice_issuing_country, external_dsp_id)),
  getDisplaysInfoRequest: (params) => dispatch(DisplayActions.getDisplaysInfoRequest(params)),
  setShouldPromptLogin: (value, target) => dispatch(UserAccountActions.setShouldPromptLogin(value, target)),
  updateBundles: (listBundle) => dispatch(DisplayActions.updateBundles(listBundle)),
  getCategories: () => dispatch(DisplayActions.getCategoriesRequest()),
  editCampaign: (campaignId, params) => dispatch(DisplayActions.editCampaignRequest(campaignId, params)),
})

export default connect(mapStateToProps, mapStateToDispatch)(CampaignComponent)
