import { connect } from 'react-redux'
import ReplicateCampaignComponent from "../components/ReplicateCampaignComponent";
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display}) => ({
  replicateCampaignId: display.replicateCampaignId,
  replicatingCampaign: display.replicatingCampaign
})

const mapStateToDispatch = (dispatch) => ({
  replicateCampaign: (campaignId, params) => dispatch(DisplayActions.createReplicateCampaignRequest(campaignId, params)),
  history: (path) => dispatch(DisplayActions.history(path)),
})

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ReplicateCampaignComponent);
