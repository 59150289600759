import moment from "moment";
import React from "react";
import markerBusOnCart from "../images/marker-bus-on-cart.png";
import markerBus from "../images/marker-bus.png";
import markerCircuitIndoorOnCart from "../images/marker-circuit-indoor-on-cart.png";
import markerCircuitIndoor from "../images/marker-circuit-indoor.png";
import markerCircuitOutdoorOnCart from "../images/marker-circuit-outdoor-on-cart.png";
import markerCircuitOutdoor from "../images/marker-circuit-outdoor.png";
import markerIndoorOnCart from "../images/marker-indoor-on-cart.png";
import markerIndoor from "../images/marker-indoor.png";
import markerLatinadOnCart from "../images/marker-latinad-on-cart.png";
import markerLatinad from "../images/marker-latinad.png";
import markerOutdoorOnCart from "../images/marker-outdoor-on-cart.png";
import markerOutdoor from "../images/marker-outdoor.png";
import markerPosOnCart from "../images/marker-pos-on-cart.png";
import markerPos from "../images/marker-pos.png";
import markerTaxiOnCart from "../images/marker-taxi-on-cart.png";
import markerTaxi from "../images/marker-taxi.png";
import bus from "../images/iconType/bus.png";
import indoor from "../images/iconType/indoor.png";
import outdoor from "../images/iconType/outdoor.png";
import pos from "../images/iconType/pos.png";
import circuitIndoor from "../images/iconType/circuit_indoor.png";
import circuitOutdoor from "../images/iconType/circuit_outdoor.png";
import taxi from "../images/iconType/taxi.png";
import contains from "ramda/src/contains";

export function convertBounds(viewport) {
  return {
    lat_ne: viewport.getNorthEast().lat(),
    lng_ne: viewport.getNorthEast().lng(),
    lng_sw: viewport.getSouthWest().lng(),
    lat_sw: viewport.getSouthWest().lat()
  };
}

export function getLocationType(type, t) {
  if (type === "outdoor") {
    return "Outdoor";
  }
  if (type === "indoor") {
    return "Indoor";
  }
  if (type === "buses") {
    return t('Mobile');
  }
  if (type === "pos") {
    return t('Point of sale');
  }
};

export function getIconType(locationType, displayType, onCart, isPin ) {
  isPin = isPin == 'isPin' || false;
  if (locationType === "buses") {
    if(!isPin) return bus;

    if (onCart) {
      return markerBusOnCart;
    } else {
      return markerBus;
    }
  } else if (locationType === "indoor" && displayType === "normal") {
    if(!isPin) return indoor;

    if (onCart) {
      return markerIndoorOnCart;
    } else {
      return markerIndoor;
    }
  } else if (locationType === "indoor" && displayType === "main") {
    if(!isPin) return circuitIndoor;

    if (onCart) {
      return markerCircuitIndoorOnCart;
    } else {
      return markerCircuitIndoor;
    }
  } else if (locationType === "outdoor" && displayType === "normal") {
    if(!isPin) return outdoor;

    if (onCart) {
      return markerOutdoorOnCart;
    } else {
      return markerOutdoor;
    }
  } else if (locationType === "outdoor" && displayType === "main") {
    if(!isPin) return circuitOutdoor;

    if (onCart) {
      return markerCircuitOutdoorOnCart;
    } else {
      return markerCircuitOutdoor;
    }
  } else if (locationType === "pos") {
    if(!isPin) return pos;

    if (onCart) {
      return markerPosOnCart;
    } else {
      return markerPos;
    }
  } else if (locationType === "taxi") {
    if(!isPin) return taxi;

    if (onCart) {
      return markerTaxiOnCart;
    } else {
      return markerTaxi;
    }
  } else {
    if (onCart) {
      return markerLatinadOnCart;
    } else {
      return markerLatinad;
    }
  }
}

export function adaptParams(params) {
  const newParams = {};

  if (params.date_from) newParams.date_from = params.date_from;
  if (params.date_to) newParams.date_to = params.date_to;
  if (params.lat_ne) newParams.lat_ne = parseFloat(params.lat_ne);
  if (params.lat_sw) newParams.lat_sw = parseFloat(params.lat_sw);
  if (params.lng_ne) newParams.lng_ne = parseFloat(params.lng_ne);
  if (params.lng_sw) newParams.lng_sw = parseFloat(params.lng_sw);
  if (params.price_min) newParams.price_min = parseInt(params.price_min, 10);
  if (params.price_max) newParams.price_max = parseInt(params.price_max, 10);
  if (params.shows_per_day_min) newParams.shows_per_day_min = parseInt(params.shows_per_day_min, 10);
  if (params.location_type) newParams.location_type = params.location_type;
  if (params.location_sub_type) newParams.location_sub_type = params.location_sub_type;
  if (params.size_type) newParams.size_type = params.size_type;
  if (params.page) newParams.page = parseInt(params.page, 10);
  if (params.currency) newParams.currency = params.currency;
  if (params.external_dsp_id) newParams.external_dsp_id = params.external_dsp_id;

  return newParams;
}

export function updateParams(oldParams, newParams) {
  return JSON.parse(JSON.stringify(Object.assign({}, oldParams, newParams)));
}

export function getDisplayingLocation(locations, translate) {
  if (locations.length === 0 || locations.length === 3) return translate('All');
  const showingDisplay = [];
  if (locations.includes("outdoor")) showingDisplay.push("Outdoor");
  if (locations.includes("indoor")) showingDisplay.push("Indoor");
  if (locations.includes("buses")) showingDisplay.push(translate('Mobile'));
  if (locations.includes("pos")) showingDisplay.push(translate('Point of sale'));

  return showingDisplay.join(", ");
}

export function getDisplayingSizes(sizes, translate) {
  if (sizes.length === 0 || sizes.length === 4) return translate('All');
  const showingDisplay = [];
  if (sizes.includes("small")) showingDisplay.push(translate('Small'));
  if (sizes.includes("medium")) showingDisplay.push(translate('Medium'));
  if (sizes.includes("big")) showingDisplay.push(translate('Big'));
  if (sizes.includes("giant")) showingDisplay.push(translate('Giant'));

  return showingDisplay.join(", ");
}

export function getAspectRatio(w, h) {
  const n = gcd(w, h);
  return `${w / n}:${h / n}`;
}

export function getSimilarAspectRatio(w, h, translate) {
  const n = gcd(w, h);
  if (((w/n)/(h/n)) === 1 ) {
    return translate('Square')
  } else if (((w/n)/(h/n)) < 1 ) {
    return translate('Vertical')
  } else if (((w/n)/(h/n)) > 1 && ((w/n)/(h/n)) < 2) {
    return translate('Horizontal')
  } else if (((w/n)/(h/n)) >= 2) {
    return translate('Super horizontal')
  }
}

export function getValidationError(errors, translate) {
  let e;
  if (!Array.isArray(errors)) {
    e = [errors];
  } else {
    e = errors;
  }

  if (e[0].errors && e[0].errors.username) {
    if (e[0].errors.username[0].includes("The username has already been taken.")) {
      return translate('The email entered already exists');
    }
  }
  if (e[0].errors && e[0].errors.email) {
    if (e[0].errors.email[0].includes("The email has already been taken.")) {
      return translate('The email entered already exists');
    }
    if (e[0].errors.email[0].includes("The email must be a valid email address.")) {
      return translate('Enter a valid email');
    }
  }
  if (e[0].errors && e[0].errors.first_name) {
    if (e[0].errors.first_name[0].includes("The first name may not be greater than 255 characters.")) {
      return translate('The name must be less than 255 characters.');
    }
  }
  if (e[0].errors && e[0].errors.last_name) {
    if (e[0].errors.last_name[0].includes("The last name may not be greater than 255 characters.")) {
      return translate('The last name must be less than 255 characters.');
    }
  }
  if (e[0].errors && e[0].errors.company_name) {
    if (e[0].errors.company_name[0].includes("The company name has already been taken.")) {
      return translate('The company name already exists. Please enter another.');
    }
  }
  if (e[0].errors && e[0].errors.two_factor_code) {
    if (e[0].errors.two_factor_code[0].includes("The two factor code must be 6 digits.")) {
      return translate('The code must have 6 digits');
    }
  }

  if (e[0].message.includes("Invalid credentials.")) {
    return translate('Incorrect username or password');
  }
  if (e[0].message.includes("Validation errors.")) {
    return translate('Fill in all fields');
  }
  if (e[0].message.includes("Mail not found on our database.")) {
    return translate('We cant find that email in our database, try another one.');
  }
  if (e[0].message.includes("Invalid code 2FA")) {
    return translate('Incorrect security code');
  }
  if (e[0].message.includes("User's account is not verified")) {
    return translate("Your account is not verified");
  }
  if (e[0].message.includes("No result for this model.")) {
    return translate('We cant find that email in our database, try another one.');
  }
  if (e[0].message.includes("The user is already verified.")) {
    return translate('This account has already been verified.');
  }
  if (e[0].message.includes("Too Many Attempts.")) {
    return translate('Too many attempts');
  }
  if (e[0].message.includes("User's account is not activated") || e[0].message.includes("User deactivated")) {
    return translate('Account is not activated');
  }
  if (e[0].message.includes("Password changed")) {
    return translate('Password changed');
  }

  return translate('Unexpected error');
}

export function getDatesRange(date_from, date_to) {
  let from, to;
  if (!date_from && !date_to) {
    from = moment().add(1, "days");
    to = moment().add(30, "day");
  } else if (!date_to) {
    from = date_from;
    to = moment(date_from).add(30, "days");
  } else if (!date_from) {
    from = moment(date_to).subtract(30, "days");
    if (from.isBefore(moment())) from = moment().add(1, "days");
    if (from.isAfter(date_to)) from = date_to;
    to = date_to;
  } else {
    from = date_from;
    to = date_to;
  }

  return {
    date_from: from.format("YYYY-MM-DD"),
    date_to: to.format("YYYY-MM-DD")
  };
}
export function getWorkingHoursList(t, displayHoursList, workingHoursList){
  for (var i = 0; i < 7; i++) {
    if (displayHoursList.find(wh => wh.day_of_week === i)) {
      const arrayHours = displayHoursList.filter(wh => wh.day_of_week === i);
      if (i === 0) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
                <span className="primary">{t('Sun')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary">{t('Sun')}:</span><br/>
              </li>
            );
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}> {arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}> {arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
      if (i === 1) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" >{t('Mon')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary" >{t('Mon')}:</span><br/>
              </li>
            );
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
      if (i === 2) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
                <span className="primary">{t('Tue')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary">{t('Tue')}:</span><br />
              </li>
            );
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
      if (i === 3) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
              <span className="primary">{t('Wed')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary">{t('Wed')}:</span><br />
              </li>
            );
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
      if (i === 4) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
                <span className="primary">{t('Thu')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary">{t('Thu')}:</span><br />
              </li>
            );
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "27px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
      if (i === 5) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
              <span className="primary">{t('Fri')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary">{t('Fri')}:</span><br />
              </li>
            );
            workingHoursList.push(
              <li key={j}>
              <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "28px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
      if (i === 6) {
        for (var j = 0; j < arrayHours.length; j++) {
          if (arrayHours[j].start_time === '00:00:00' && arrayHours[j].end_time === '00:00:00') {
            workingHoursList.push(
              <li key={j}>
              <span className="primary">{t('Sat')}: {t('Off')}</span><br />
              </li>
            );
          } else if (j === 0) {
            workingHoursList.push(
              <li key={j+'day'}>
                <span className="primary">{t('Sat')}:</span><br />
              </li>
            );
            workingHoursList.push(
              <li key={j}>
              <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          } else {
            workingHoursList.push(
              <li key={j}>
                <span className="primary" style={{marginLeft: "30px"}}>{arrayHours[j].start_time.slice(0, -3)}hs - {arrayHours[j].end_time.slice(0, -3)}hs</span><br />
              </li>
            );
          }
        }
      }
    }
    else {
      if (i === 0) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Sun')}: {t('All day')}</span><br />
          </li>
        )
      } else if (i === 1) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Mon')}: {t('All day')}</span><br />
          </li>
        )
      } else if (i === 2) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Tue')}: {t('All day')}</span><br />
          </li>
        )
      } else if (i === 3) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Wed')}: {t('All day')}</span><br />
          </li>
        )
      } else if (i === 4) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Thu')}: {t('All day')}</span><br />
          </li>
        )
      } else if (i === 5) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Fri')}: {t('All day')}</span><br />
          </li>
        )
      } else if (i === 6) {
        workingHoursList.push(
          <li style={{marginBottom: "3px", marginTop:"2px"}}>
          <span className="primary">{t('Sat')}: {t('All day')}</span><br />
          </li>
        )
      }
    }
  }
  return workingHoursList
}


export function getDayOfWeekDisplayWork(workingHours, date_from, date_to) {
    let listDays = [];
    let count = 0
    workingHours.forEach((workingHour) => {
        // add day that not count, when end_time and start_time is 00:00:00 the screem is down
        if (workingHour['start_time'] == '00:00:00' && workingHour['end_time'] == '00:00:00') {
            listDays.push(workingHour.day_of_week)
        }
    });
    for (var dateToEval = moment(date_from); moment(date_to).diff(dateToEval, 'days') >= 0; dateToEval.add(1, 'days')) {
      if (listDays.find(element => element == dateToEval.day()) == undefined){
        count ++
      }
    }
    return count;
}

export function getTotalPice(listCart, date_from, date_to){
    return (
      listCart.reduce((acc, display) => {
        return acc + display.price_per_day *
        getDayOfWeekDisplayWork(display.working_hours, date_from, date_to);
      }, 0) * 100
    ) / 100
}

export function getCPM(campaingPrice, total_impacts){
    if(total_impacts==0) return 0
    let CPM = (1000 * campaingPrice) / total_impacts;
    CPM = CPM.toFixed(2);
    return CPM
}

const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));


export function updatePriceCartByDiscountDisplay(displays, date_from, date_to, updatePriceCartByDiscountRedux){
  displays.forEach( function(display) {
    if(display.rank_discounts == undefined || (display.rank_discounts && display.rank_discounts.length < 1))
      return

    let diffDay = moment(date_to).diff(date_from, "days") + 1;
    let actualDiscount= 0
    display.rank_discounts.forEach( function(discount) {
      if(diffDay >= discount.min_range)
        actualDiscount = discount.discount
    })
    let price_calculate = display.base_price_per_day ? display.base_price_per_day : display.price_per_day
    let price = price_calculate - ((price_calculate * actualDiscount)/100)
    if(price != display.price_per_day){
      updatePriceCartByDiscountRedux(display.id, price)
    }
  })
}

// Get config for Metabase, depending of the values setting audience or audience_refined
export function getRefinedAudience(user, programmaticProvider){
  return user && user.company && user.company.see_refined_audience == 1;
}

export function belongsToBundle (display, listBundle) {
    // Check if the display has bundles of campaign
    if (!display.bundles || !listBundle) return;

    if (display.bundles.length > 0 && listBundle.length > 0){
      let matchBundle = display.bundles.filter(bundle => listBundle.includes(bundle.id));
      if (matchBundle.length > 0){
        return matchBundle;
      }
    }
    return;
}

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
}

export function getFilterDisplaysForContains (display, user) {
  let displayContains = display.constraints
  let filter = [
    {contains: "Solo agencias aprobadas por el dueño del medio", companies: [158, 130, 153, 160, 254, 754, 806, 805, 813, 913, 804, 884, 969, 836, 757, 936, 626, 982]},
    // {contains: "", display: []}
  ]

  if (!filter.some(item => item.contains === displayContains)) {
    return true;
  }

  if (user && user.company && user.company.id) {
    return filter.find(item => item.contains === displayContains).companies.includes(user.company.id);
  }

  return false;
}

// Check if the display is part of a bundle
export function checkIfBundle(displayBundles, listBundle) {
  let count = 0;
  for (const bundle of displayBundles) {
    if (listBundle && listBundle.includes(bundle.id)) {
      count++;
    }
  }
  return count;
};

export function calculateImpressions (type, relation, reportSingleData, display){
  if(type == "to_consumed"){
    let diff_promised_shows = relation.promised_shows + relation.promised_shows_subsidized
    let promisedInSingleData = reportSingleData.report ? reportSingleData.report.find(element => element.display === display.id && element.content === relation.content.id) : null
    return ((diff_promised_shows) - (promisedInSingleData ? promisedInSingleData.shows : 0))
  }
  if(type == "to_consumed_not_subsidized"){
    let diff_promised_shows = relation.promised_shows
    let promisedInSingleData = reportSingleData.report ? reportSingleData.report.find(element => element.display === display.id && element.content === relation.content.id) : null
    return ((diff_promised_shows) - (promisedInSingleData ? promisedInSingleData.shows : 0))
  }
}
