import React, { Component } from "react";
import ExpansionHolder from "./Styles/expansionStyle";

import image from "../images/map-expansion.png";

import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";

class Expansion extends Component {
  state = { visible: false };

  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  showModal = () => {
    this.setState({
      visible: true
    });
    if (this.video.current) this.video.current.internalPlayer.playVideo();
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
    this.video.current.internalPlayer.pauseVideo();
  };

  render() {
    const { t } = this.props;
    return (
      <ExpansionHolder>
        <div className="expansionContainer">
          <h1 style={{ color: Colors.blueDark}}>{t('Where we are?')}</h1><br />
          <h2>
            <span style={{ fontWeight: "bold", fontSize: "25px"}}>{t('Argentina')}</span> <br />
            <span style={{ fontSize: "16px" }}>argentina@latinad.com | +549 261 501 9508</span>
          </h2>
          <h2>
            <span style={{ fontWeight: "bold", fontSize: "25px"}}>{t('Peru')}</span> <br />
            <span style={{ fontSize: "16px" }}>peru@latinad.com | +51 965 244 671</span>
          </h2>
          <h2>
            <span style={{ fontWeight: "bold", fontSize: "25px"}}>{t('Ecuador')}</span> <br />
            <span style={{ fontSize: "16px" }}>ecuador@latinad.com | +51 965 244 671</span>
          </h2>
          <h2>
            <span style={{ fontWeight: "bold", fontSize: "25px"}}>{t('Colombia')}</span> <br />
            <span style={{ fontSize: "16px" }}>colombia@latinad.com | +57 314 4891097</span>
          </h2>
          <h2>
            <span style={{ fontWeight: "bold", fontSize: "25px"}}>{t('Central America')}</span> <br />
            <span style={{ fontSize: "16px" }}>cam@latinad.com | +549 261 501 9508</span>
          </h2>
          <h2>
            <span style={{ fontWeight: "bold", fontSize: "25px"}}>{t('Chile')}</span> <br />
            <span style={{ fontSize: "16px" }}>chile@latinad.com | +56 7423 4580</span>
          </h2>
          <img src={image} className="imageBg" alt="" />
        </div>
      </ExpansionHolder>
    );
  }
}
export default withTranslation()(Expansion)
