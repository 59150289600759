import React, { Component } from 'react'
import CheckoutDetailsHolder from './Styles/checkoutDetailsStyle'
import {
  Button,
  Card,
  Select,
  Row,
  Col,
  Input,
  Icon,
  Tooltip,
  Form,
  Modal
} from 'antd'
import { history } from '../redux';
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import NumberFormat from "./NumberFormat";
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole, getUrl } from "../services/utils"

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "6px", top: "17px"}}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "6px", top: "17px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

class CheckoutDetails extends Component {

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  state = {
    errors: {},
    visibleSpotModal: false,
    modalDisplayName: '',
    modalContentFile: '',
    modalContentType: '',
    lastFormSubmitted: "",
    proposalLoading: false,
    tax_info_text: '',
  };

  handleSendCoupon() {

    const errors = {};

    if (!this.state.couponNumber) {
      errors["couponNumber"] = this.props.t('Can not be blank');
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors
      });

      return false;
    }

    const couponInfo = {
      code: this.state.couponNumber
    }

    this.props.createCouponRequest(couponInfo);

  };


  handleDeleteCoupon(campaignId, couponId) {

    this.props.deleteCouponRequest(campaignId, couponId);

  };

  handleInputChange(event) {
    const target = event.target;

    this.setState({
      [target.name]: target.value
    });
  }

  showSpotModal = (diplayName, contentFile, contentType) => {
    this.setState({
      visibleSpotModal: true,
      modalContentType: 'image',
      modalContentFile: '',
    });
    setTimeout(() => {
      this.setState({
        visibleSpotModal: true,
        modalDisplayName: diplayName,
        modalContentFile: contentFile,
        modalContentType: contentType,
      });
    }, 300)
  }

  okSpotModal = e => {
    this.setState({
      visibleSpotModal: false,
    });
  };

  cancelSpotModal = e => {
    this.setState({
      visibleSpotModal: false,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loadingProposal && !nextProps.loadingProposal && this.state.proposalLoading) {
      let lenguageParse
      switch (nextProps.i18n.language) {
        case "es":
          lenguageParse = "esLA";
          break;
        case "es_ar":
          lenguageParse = "es";
          break;
        default:
          break;
      }
      const parameter = `lenguage=${lenguageParse || nextProps.i18n.language}&token=${nextProps.proposalToken.token}`
      window.open(getUrl(window.location, "proposal.html", parameter));
      this.setState({ proposalLoading: false });
    }
    // Tax text for Tooltip
    var tax_info_object =  nextProps.campaign &&
                      nextProps.campaign.tax_info ?
                      Object.values(nextProps.campaign.tax_info): [];
    var tax_info_text_prev = "";
    for (let index = 0; index < tax_info_object.length; index++) {
      if (tax_info_object[index].tax_name !== 'WITHHOLDING TAX') {
        tax_info_text_prev = tax_info_text_prev + tax_info_object[index].tax_name + ' (' + parseFloat(tax_info_object[index].tax_value) + '%). ';
      }
    }
    this.setState((state) => {
      return {
        tax_info_text: tax_info_text_prev
      };
    });
  }

  getProposal = () => {
    this.setState({ proposalLoading: true });
    this.props.getProposalRequest({
        campaign_id: this.props.campaign.id
    })
  }

  render() {
    const { t, user } = this.props;
    const { campaign, payerCosts, couponData, couponErrors, installments, onInstallmentsChange, deleteCouponFlag } = this.props;
    const installmentsInformation = payerCosts.find(pc => pc.installments === installments);
    const label = installmentsInformation && installmentsInformation.labels.find(l => l.indexOf('CFT') >= 0);
    const { errors } = this.state;

    let cft = '';
    let tea = '';

    if (label) {
      [cft, tea] = label.split('|');

      cft = cft.split('_')[1];
      tea = tea.split('_')[1];
    }

    var sliderSettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };

    return (
      <CheckoutDetailsHolder>
        <Card className="detailsCard">
          <h3>{t('Summary')}</h3>
          <h4>{t('Campaign')}: {campaign.name}{" "}
          - {campaign.contents_displays.length} {t('screen')}{campaign.contents_displays.length === 1 ? "" : "s"}
          </h4>
          {campaign.contents_displays.map((display) => {
            return (
              <div className="itemContainer" key={display.id.toString()}>
                <Col xs={18} style={{ paddingLeft: "0", paddingRight: "10px" }}>
                  <p className="itemName" style={{ marginBottom: "0px" }}>{display.display_name}</p><br />
                  <p className="itemName" style={{ marginBottom: "10px", fontSize: "11px" }}>
                    {display.display_administrative_area_level_2}, {display.display_administrative_area_level_1}{" "}
                    <span onClick={() => this.showSpotModal(display.display_name, display.content_child_contents ? display.content_child_contents : display.content_file, display.content_type)}>
                      ({t('See spot')}{display.content_child_contents ? "s" : null})
                    </span>
                  </p>
                </Col>
                <Col xs={6} style={{ paddingLeft: "0", paddingRight: "0"}}>
                  <p className="itemPrice"><NumberFormat value ={display.paid_amount_with_profit} currency={campaign.currency}/></p>
                </Col>
              </div>
            )
          })}

          <Modal
            title={this.state.modalDisplayName}
            visible={this.state.visibleSpotModal}
            onOk={this.okSpotModal}
            onCancel={this.cancelSpotModal}
            footer={[
              <Button key="back" onClick={this.cancelSpotModal}>
                {t('Close')}
              </Button>,
            ]}
          >
            {this.state.modalContentType === 'video' ? (
              <span>
                {Array.isArray(this.state.modalContentFile) ? (
                  <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                    {this.state.modalContentFile && this.state.modalContentFile.map((content) => {
                      return (
                        <video controls style={{width: "100%"}}>
                          <source src={ content.file } type="video/mp4" />
                        </video>
                      )
                    })}
                  </Slider>
                ) : (
                  <video controls style={{width: "100%"}}>
                    <source src={ this.state.modalContentFile } type="video/mp4" />
                  </video>
                )}
              </span>
            ) : (
              <span>
                {Array.isArray(this.state.modalContentFile) ? (
                  <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                    {this.state.modalContentFile && this.state.modalContentFile.map((content) => {
                      return (
                        <img src={ content.file } style={{width: "100%"}} alt="" />
                      )
                    })}
                  </Slider>
                ) : (
                  <img src={ this.state.modalContentFile } style={{width: "100%"}} alt="" />
                )}
              </span>
            )}
          </Modal>

          {couponData.coupons ? (
            <div className="itemContainer">
              <Col xs={16} style={{ paddingLeft: "0", paddingRight: "10px" }}>
                <p className="itemName">
                  <Tooltip placement="top" title={t('Remove discount')}>
                    {!this.props.deletingCoupon ? (
                      <span>
                        <Icon type="close-circle" className="iconCloseDiscount" onClick={() => this.handleDeleteCoupon(campaign.id, couponData.coupons[0].id)}/>{" "}
                      </span>
                    ) : (<Icon type="loading" />)}{" "}
                  </Tooltip>
                  {t('Discount')}
                </p>
              </Col>
              <Col xs={8} style={{ paddingLeft: "0", paddingRight: "0" }}>
                <p className="itemPrice">-<NumberFormat value ={Math.round((campaign.original_price_with_profit - couponData.total_price) * 100 )/100} /></p>
              </Col>
            </div>
          ) : null}

          {!couponData.coupons && campaign.coupons && campaign.coupons.length > 0 && !deleteCouponFlag ? (
            <div className="itemContainer">
              <Col xs={16} style={{ paddingLeft: "0", paddingRight: "10px" }}>
                <p className="itemName">
                  <Tooltip placement="top" title={t('Remove discount')}>
                    {!this.props.deletingCoupon ? (
                      <span>
                        <Icon type="close-circle" className="iconCloseDiscount" onClick={() => this.handleDeleteCoupon(campaign.id, campaign.coupons[0].id)}/>{" "}
                      </span>
                    ) : (<Icon type="loading" />)}{" "}
                  </Tooltip>
                  {t('Discount')}
                </p>
              </Col>
              <Col xs={8} style={{ paddingLeft: "0", paddingRight: "0" }}>
                <p className="itemPrice">-<NumberFormat value ={Math.round((campaign.original_price_with_profit - couponData.total_price_with_profit) * 100 )/100} /></p>
              </Col>
            </div>
          ) : null}
          <Row>
            <Col xs={24} style={{ paddingLeft: "0", paddingRight: "0" }}>
              {campaign && campaign.id ?
                <>
                  <div class="subTotal"></div>
                  { campaign.invoice_issuing.alpha_2_code != 'F' &&
                    <div className="subTotalText">
                      <h3>{t('Subtotal cost')}: <NumberFormat value ={campaign.total_price_with_profit} currency={campaign.currency}/></h3>
                    </div>
                  }
                  <div className="total">
                    <span>
                      <h2>
                        {t('Total cost')}: <NumberFormat value ={parseFloat(campaign.total_price_with_profit) + (parseFloat(campaign.total_price_with_profit) * parseFloat(campaign.tax) / 100)} currency={campaign.currency}/>
                        {campaign.invoice_issuing.alpha_2_code != 'F' &&
                          <Tooltip placement="topRight" title={t('Tax details') + ': ' + this.state.tax_info_text}>
                            <Icon className="taxTooltip" type="info-circle" />
                          </Tooltip>
                        }
                      </h2>
                    </span>
                  </div>
                  {campaign.total_price_company_country_currency &&
                    <div className="total">
                      <span>
                        <h4 style={{color: "#aaa"}}>
                          {t('Suggested price in local currency')}: <NumberFormat value={campaign.total_price_company_country_currency} currency={campaign.company_country_currency} />
                          <Tooltip placement="topRight" title={t('It is the price that you should use when implementing the campaign in the chosen DSP, only if you want to do it in local currency.')}>
                            <Icon className="taxTooltip" type="info-circle" />
                          </Tooltip>
                        </h4>
                      </span>
                    </div>
                  }
                </>
              : null }
            </Col>
          </Row>
          <Row>
            <Col xs={24} style={{ paddingLeft: "0", paddingRight: "0" }}>
              {payerCosts.length > 0 &&
                <Select
                className="payerCosts"
                value={installments}
                onChange={value =>
                  onInstallmentsChange(value)
                }
                >
                  {payerCosts.map((pc) => {
                    return (
                      <Select.Option key={pc.installments} value={pc.installments}>
                        {pc.recommended_message}
                      </Select.Option>
                    )
                  })}
                </Select>
              }

              {label && (
                <div>
                  <div style={{ fontSize: 12 }}>CFT: {cft}</div>
                  <div style={{ fontSize: 16 }}>TEA: {tea}</div>
                </div>
              )}
            </Col>
          </Row>
          {user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]) ? (
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{paddingRight: '10px', paddingBottom: '10px'}}>
                <Tooltip placement="bottom" title={t('Generate proposal')}>
                  <Button
                    className="checkoutButton"
                    style={{marginRight: "10px"}}
                    loading={this.state.proposalLoading}
                    onClick={this.getProposal}
                  >
                    {t('Proposal')}
                  </Button>
                </Tooltip>
              </Col>
              { (campaign.external_dsp_id == null || !campaign.smart_campaign) ||
                (user && hasAccessRole(user.roles,[UserRoleEnum.superModeratorAgency, UserRoleEnum.superAdmin, UserRoleEnum.superModeratorMediaCompany])) ?
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip placement="bottom" title={t('Finish process for subsequent approval')}>
                    <Button
                      loading={this.props.creatingToken}
                      className="checkoutButton"
                      type="primary"
                      onClick={this.props.onPaymentButtonClick}
                    >
                      {t('Finish')}
                    </Button>
                  </Tooltip>
                </Col>:
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    loading={this.props.creatingToken}
                    className="checkoutButton"
                    type="primary"
                    onClick={() => history.push("/campaign/" + campaign.id)}>
                  {t('Go to my campaign')}
                  </Button>
                </Col>
              }
            </Row>
          ) : (
            <Button loading={this.props.creatingToken} className="checkoutButton" type="primary" onClick={this.props.onPaymentButtonClick}>
              {this.props.paymentMethod === 'mp' ? t('Go Mercado Pago') : t('Make payment')}
            </Button>
          )}
        </Card>
        {user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember]) ? (
          <Card className="detailsCard" style={{ display: 'none' }}>
            <h3>{t('Apply discount coupon')}</h3>
            <Form layout="inline">
              <Row
                type="flex"
                gutter={6}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Col xs={24}>
                  <Form.Item
                    validateStatus={!!errors["couponNumber"] && "error"}
                    help={errors["couponNumber"]}
                  >
                    <Input
                      id="couponNumber"
                      placeholder={t('Coupon code')}
                      name="couponNumber"
                      onFocus={() =>
                        this.setState({
                          couponNumber: this.state.couponNumber
                        })
                      }
                      onChange={this.handleInputChange}
                      onPressEnter={() => this.handleSendCoupon()}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div className="cardInfoError">
                    {couponErrors
                      ? couponErrors
                      : null}
                  </div>
                </Col>
              </Row>
            </Form>
            <Button loading={this.props.creatingCoupon} className="checkoutButton" type="primary" onClick={() => this.handleSendCoupon()}>
              {t('Apply')}
            </Button>
          </Card>
        ) : (
          <Card className="detailsCard" style={{ display: 'none' }}>
            <h3>{t('Apply discount coupon')}</h3>
            <Form layout="inline">
              <Row
                type="flex"
                gutter={6}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Col xs={24}>
                  <Form.Item
                    validateStatus={!!errors["couponNumber"] && "error"}
                    help={errors["couponNumber"]}
                  >
                    <Input
                      id="couponNumber"
                      placeholder={t('Coupon code')}
                      name="couponNumber"
                      onFocus={() =>
                        this.setState({
                          couponNumber: this.state.couponNumber
                        })
                      }
                      onChange={this.handleInputChange}
                      onPressEnter={() => this.handleSendCoupon()}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div className="cardInfoError">
                    {couponErrors
                      ? couponErrors
                      : null}
                  </div>
                </Col>
              </Row>
            </Form>
            <Button loading={this.props.creatingCoupon} className="checkoutButton" type="primary" onClick={() => this.handleSendCoupon()}>
              {t('Apply')}
            </Button>
          </Card>
        )}
      </CheckoutDetailsHolder>
    )
  }
}
export default withTranslation()(CheckoutDetails)
