import React, { Component } from "react";
import moment from "moment";
import CheckoutStepsHolder from "./Styles/checkoutStepsStyle";
import { Row, Col, Icon, Button, Tooltip, Popover, Affix } from "antd";
import { withRouter } from "react-router";
import { DateRangePicker } from "react-dates";
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION
} from "react-dates/constants";
import NumberFormat from "./NumberFormat";
import { withTranslation } from 'react-i18next';
import Colors from "../themes/Colors";
import { getRelationId } from "../services/utils";
import { getTotalPice } from "../services/display";
import { updatePriceCartByDiscountDisplay} from "../services/display";
class CheckoutStepsComponent extends Component {
  state = {
    date_from: moment(this.props.date_from || new Date()),
    date_to: moment(this.props.date_to || new Date()),
    visibleDatesFilter: false,
  };
  handleVisibleDatesFilterChange = visibleDatesFilter => {
    this.setState({ visibleDatesFilter });
  };

 UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.programmatic === "traditional"){
      if((nextProps.editCampaignActive && nextProps.editCampaignActive != this.props.editCampaignActive) ||
        (this.props.date_to != nextProps.date_to || this.props.date_from != nextProps.date_from) ){
        const {updatePriceCartByDiscount} = this.props
        updatePriceCartByDiscountDisplay(nextProps.cart, nextProps.date_from, nextProps.date_to, updatePriceCartByDiscount)
      }
    }
 }
  render() {
    const { t, i18n, currentCampaign, invoiceIssuingCountry, user, editCampaignActive, dsp, isSuperUser} = this.props;

    let external_dsp = editCampaignActive ? (currentCampaign.external_dsp && currentCampaign.external_dsp.name) || 'Latinad' : (dsp && dsp.name) || 'Latinad'

    moment.locale(i18n.language);
    const days =
      moment(this.props.date_to).diff(this.props.date_from, "days") + 1; //+1 por el dia inicial
    return (
      <CheckoutStepsHolder>
        <Row
          type="flex"
          gutter={[1,8]}
          align="top"
          justify="space-between"
          style={{ width: "100%", display: "flex", margin: "0px" }}
        >
          <Col
            xs={{span:12, order:1}}
            sm={{span:12, order:1}}
            md={{span:12, order:1}}
            lg={{span:12, order:1}}
            xl={{span:!this.props.editCampaignActive ? 1 : 2, order:1}}
          >
            {!this.props.editCampaignActive ? (
              <Affix offsetTop={130}>
                <Button
                  className="backButton"
                  style={{marginBottom: "0px"}}
                  onClick={() => this.props.history.goBack()}
                >
                  <Icon
                    style={{ fontSize: "18px", color: Colors.primary }}
                    type="left"
                  />{" "}
                  {t("Edit")}
                </Button>
              </Affix>
            ) : this.props.currentCampaign.payment_status === "pending" ||
              this.props.currentCampaign.payment_status === "cancelled" ? (
              <Affix offsetTop={130}>
                <Button
                  className="backButton"
                  style={{marginBottom: "0px"}}
                  onClick={() => this.props.history.replace(`/map`)}
                >
                  <Icon
                    style={{ fontSize: "18px", color: Colors.primary }}
                    type="left"
                  />{" "}
                  {t("Mapa")}
                </Button>
              </Affix>
            ) : null}
          </Col>
          <Col
            xs={{span:24, order:3}}
            sm={{span:24, order:3}}
            md={{span:24, order:3}}
            lg={{span:24, order:3}}
            xl={{span:6, order:3}}
            className="date"
          >
            <Popover
              content={
                <div className="popover">
                  {t("Change campaign dates")}
                  <DateRangePicker
                    startDatePlaceholderText={t("Start date")}
                    endDatePlaceholderText={t("Finish date")}
                    startDate={moment(this.props.date_from)}
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={moment(this.props.date_to)}
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    minimumNights={0}
                    onDatesChange={({ startDate, endDate }) => {
                      this.props.setDates(
                        startDate && startDate.format("YYYY-MM-DD"),
                        endDate && endDate.format("YYYY-MM-DD")
                      );
                    }} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    } // PropTypes.func.isRequired,
                    hideKeyboardShortcutsPanel={true}
                    customArrowIcon={<div />}
                    withPortal={window.matchMedia("(max-width: 400px)").matches}
                    orientation={
                      window.matchMedia("(max-width: 400px)").matches
                        ? VERTICAL_ORIENTATION
                        : HORIZONTAL_ORIENTATION
                    }
                  />
                </div>
              }
              trigger="click"
              placement="bottomLeft"
              visible={this.state.visibleDatesFilter &&
              (!this.props.editCampaignActive ||
              ((currentCampaign.smart_campaign ||(!currentCampaign.smart_campaign && currentCampaign.payment_status!="approved"))))}
              onVisibleChange={this.handleVisibleDatesFilterChange}
            >
              <div className="filterButton">
                <div className="filterButtonDates">
                  <div className="filterButtonDate">
                    {t("Start date")}
                    {i18n.language === "en" ? (
                      <p>
                        {moment(this.props.date_from).format("MMMM DD[,] YYYY")}
                      </p>
                    ) : (
                      <p>
                        {moment(this.props.date_from).format(
                          "DD [de] MMMM [de] YYYY"
                        )}
                      </p>
                    )}
                  </div>
                  <div>
                    {t("Finish date")}
                    {i18n.language === "en" ? (
                      <p>
                        {moment(this.props.date_to).format("MMMM DD[,] YYYY")}
                      </p>
                    ) : (
                      <p>
                        {moment(this.props.date_to).format(
                          "DD [de] MMMM [de] YYYY"
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <Icon
                  style={{
                    fontSize: "13px",
                    color: Colors.secondary,
                    marginLeft: "10px",
                  }}
                  type="caret-down"
                />
              </div>
            </Popover>
          </Col>
          <Col
            xs={{span:24, order:5}}
            sm={{span:24, order:5}}
            md={{span:24, order:5}}
            lg={{span:24, order:5}}
            xl={{span:3, order:3}}
            className="checkoutInfo"
            align="center"
          >
            {t("Number of screens")}
            <br />
            <span className="displaysQuantity">{this.props.cart.length}</span>
          </Col>
          {!(this.props.programmatic === "traditional" && !isSuperUser) ?
          <Col
            xs={{span:24, order:4}}
            sm={{span:24, order:4}}
            md={{span:24, order:4}}
            lg={{span:24, order:4}}
            xl={{span:4, order:3}}
            className="checkoutInfo"
            align="center"
          >
            DSP
            { external_dsp != 'Latinad' ?
              <>
                <Tooltip
                  placement="right"
                  style={{marginBottom: "25px"}}
                  title={
                  <span>{t("info_dsp")}</span>
                  }>
                    <Icon type="info-circle" style={{ marginLeft: "10px"}} />
                </Tooltip>
                <br />
                <span className="displaysQuantity">
                  {external_dsp}
                </span>
              </>: <><br /> {external_dsp} </>}
          </Col>
          :null }
          <Col
            xs={{span:24, order:6}}
            sm={{span:24, order:6}}
            md={{span:24, order:6}}
            lg={{span:24, order:6}}
            xl={{span:4, order:4}}
            className="checkoutInfo"
            align="center"
          >
            {this.props.programmatic === "programmatic" ? (
              <span>
                {t("Campaign budget")}{" "}
                <Tooltip
                  placement="left"
                  title={t(
                    "You can configure the impressions per screen and see how the updated budget varies according to the impact you want."
                  )}
                >
                  <Icon type="question-circle" theme="outlined" />
                </Tooltip>
              </span>
            ) : (
              t("Total campaign price")
            )}
            <br />
            <b className="displaysQuantity">
              <NumberFormat
                tax={invoiceIssuingCountry != 'F'}
                value={ this.props.programmatic === 'programmatic' ?
                    Number(this.props.amountBudget).toFixed(2)
                    : getTotalPice(this.props.cart, this.props.date_from, this.props.date_to)
                }
                currency= {this.props.cart[0] && this.props.cart[0].id ?
                           this.props.cart[0].price_currency :
                           this.props.currency
                          }
              />
            </b>
          </Col>
          <Col
            xs={{span:12, order:2}}
            sm={{span:12, order:2}}
            md={{span:12, order:2}}
            lg={{span:12, order:2}}
            xl={{span:2, order:6}}
            className="checkoutButtonContainer"
          >
            <Affix offsetTop={130}>
              {!this.props.editCampaignActive ? (
                <Button
                  disabled={!this.props.ready ? true : false}
                  className="continueButton"
                  loading={this.props.creatingCampaign}
                  onClick={() => {
                    if (!this.props.isSubmitOk()) {
                      return;
                    }
                    return this.props.createCampaign({
                      name: this.props.campaignName,
                      is_test: this.props.isCampaignTest,
                      active: 1,
                      start_date: this.props.date_from,
                      end_date: this.props.date_to,
                      client: this.props.client.id,
                      brand: this.props.brand.id,
                      description: this.props.campaignDescription,
                      content_category_id: this.props.categoriesId,
                      currency: this.props.currency,
                      invoice_issuing_country: this.props.invoiceIssuingCountry,
                      bundles: this.props.listBundle,
                      // main_content_id: this.props.defaultContent.id,
                      external_dsp_id: this.props.dsp ? this.props.dsp.id : null,
                      main_content_id:
                        this.props.customContents[0] &&
                        this.props.customContents[0].content
                          ? this.props.customContents[0].content.id
                          : this.props.defaultContent.id,
                      smart_campaign:
                        this.props.programmatic === "programmatic"
                          ? true
                          : false,
                      contents_displays: this.props.customContents.map(
                        (content) => {
                          // const customContent = this.props.customContents.find(
                          //   c => c.displayId === display.id
                          // );
                          return {
                            display_id: content.displayId,
                            content_id:
                              content && content.content
                                ? content.content.id
                                : this.props.defaultContent.id,
                            subsidized_price: content.subsidized_price
                              ? content.subsidized_price
                              : false,
                            promised_shows_subsidized: content.promised_shows_subsidized
                              ? content.promised_shows_subsidized
                              : 0,
                            fill_screen:
                              content && content.fill_screen
                                ? content.fill_screen
                                : false,
                            promised_shows:
                              content.promised_shows &&
                              this.props.programmatic === "programmatic"
                                ? content.promised_shows
                                : null,
                            rules:
                              content && content.rules
                                ? content.rules
                                : this.props.defaultRules,
                            content_name:
                              content && content.content
                                ? content.content.name
                                : this.props.defaultContent.name,
                          };
                        }
                      ),
                    });
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {t("continue")}{" "}
                  </span>
                  {!this.props.creatingCampaign ? (
                    <Icon
                      style={{ fontSize: "18px", color: Colors.primary }}
                      type="right"
                    />
                  ) : null}
                </Button>
              ) : (
                <Button
                  disabled={!this.props.ready ? true : false}
                  className="continueButton"
                  loading={this.props.editingCampaign}
                  onClick={() => {
                    if (!this.props.isSubmitOk()) {
                      return;
                    }
                    return this.props.editCampaign(
                      this.props.currentCampaign.id,
                      {
                        name: this.props.campaignName,
                        is_test: this.props.isCampaignTest,
                        start_date: this.props.date_from,
                        end_date: this.props.date_to,
                        client: this.props.client.id,
                        brand: this.props.brand.id,
                        description: this.props.campaignDescription,
                        content_category_id: this.props.categoriesId,
                        currency: this.props.currentCampaign.currency,
                        // main_content_id: this.props.defaultContent.id,
                        bundles: this.props.listBundle,
                        main_content_id:
                          this.props.customContents[0] &&
                          this.props.customContents[0].content
                            ? this.props.customContents[0].content.id
                            : this.props.defaultContent.id,
                        smart_campaign:
                          this.props.programmatic === "programmatic"
                            ? true
                            : false,
                          contents_displays: this.props.customContents.map(
                            (content) => ({
                              display_id: content.displayId,
                              active: content.active,
                              relation_id:
                                content.relationId
                                  .toString()
                                  .search(content.displayId + "_") === 0
                                  ? null
                                  : getRelationId(content.relationId),
                              content_id:
                                content && content.content
                                  ? content.content.id
                                  : this.props.defaultContent.id,
                              fill_screen:
                                content && content.fill_screen
                                  ? content.fill_screen
                                  : false,
                              promised_shows:
                                content.promised_shows &&
                                  this.props.programmatic === "programmatic"
                                  ? content.promised_shows
                                  : 0,
                              rules:
                                content && content.rules
                                  ? content.rules
                                  : this.props.defaultRules,
                              subsidized_price: content.subsidized_price
                                ? content.subsidized_price
                                : false,
                              promised_shows_subsidized: content.promised_shows_subsidized
                                ? content.promised_shows_subsidized
                                : 0,
                              content_name:
                                content && content.content
                                  ? content.content.name
                                  : this.props.defaultContent.name,
                            })
                          ),
                      }
                    );
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {t("edit")}{" "}
                  </span>
                  {!this.props.editingCampaign ? (
                    <Icon
                      style={{ fontSize: "18px", color: Colors.primary }}
                      type="right"
                    />
                  ) : null}
                </Button>
              )}
            </Affix>
          </Col>
        </Row>
      </CheckoutStepsHolder>
    );
  }
}

const Extended = withTranslation()(CheckoutStepsComponent);
Extended.static = CheckoutStepsComponent.static;

export default withRouter(Extended);
