import React, { Component } from "react";
import InfoHolder from "./Styles/infoMediaStyle";
import { Col, Button } from "antd";
import displays from "../images/pantallas.png";
import imageLatinadCMS from "../images/products/cms.svg";
import imageLatinadSSP from "../images/products/ssp.svg";
import client1 from "../images/clients-cms/1.png";
import client2 from "../images/clients-cms/2.png";
import client4 from "../images/clients-cms/4.png";
import client6 from "../images/clients-cms/6.png";
import client7 from "../images/clients-cms/7.png";
import client8 from "../images/clients-cms/8.png";
import client9 from "../images/clients-cms/9.png";
import client10 from "../images/clients-cms/10.png";
import client11 from "../images/clients-cms/11.png";
import client12 from "../images/clients-cms/12.png";
import client13 from "../images/clients-cms/13.png";
import client14 from "../images/clients-cms/14.png";
import client15 from "../images/clients-cms/15.png";
import client16 from "../images/clients-cms/16.png";
import client17 from "../images/clients-cms/17.png";
import client18 from "../images/clients-cms/18.png";
import client19 from "../images/clients-ssp/1.png";
import client20 from "../images/clients-ssp/2.png";
import client21 from "../images/clients-ssp/3.png";
import client22 from "../images/clients-ssp/4.png";
import client23 from "../images/clients-ssp/5.png";
import client24 from "../images/clients-ssp/6.png";
import client25 from "../images/clients-ssp/7.png";
import client3 from "../images/clients-ssp/8.png";
import { withTranslation } from 'react-i18next';
import { default as SliderSlick } from "react-slick";
import Colors from "../themes/Colors";


class Info extends Component {
  state = {
    videoLatinadVisible: false,
    videoPublinetVisible: false
  };

  constructor(props) {
    super(props);
    this.video = React.createRef();
    this.clients = [
      client1,
      client2,
      client3,
      client4,
      client19,
      client6,
      client22,
      client7,
      client8,
      client9,
      client25,
      client10,
      client11,
      client12,
      client13,
      client14,
      client15,
      client16,
      client17,
      client18,
      client20,
      client21,
      client23,
      client24,
    ]
  }

  render() {

    const { t } = this.props;
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <InfoHolder >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            marginTop: "140px",
            marginBottom: "40px",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              padding: "30px",
              paddingLeft: "10%",
            }}
            className="callback"
          >
            <h1 style={{ marginBottom: "20px", color: Colors.blueDark }}>{t('Do you have screens?')}</h1>
            <h2 style={{ marginBottom: "40px" }}>
              {t('Join the largest network in America, manage your business and increase sales.')}
            </h2>
            <div>
              <img
                style={{ marginTop: "8px", marginRight: "20px", width: "120px" }}
                src={imageLatinadCMS}
                alt=""
              />
              <a className="link" target="_blank" href="https://latinad.com/medios#registro" rel="noopener noreferrer">
                <Button type="primary" shape="round">
                  {t('I dont have CMS')}
                </Button>
              </a>
            </div>
            <div style={{ marginTop: "20px"}}>
              <img
                style={{ marginTop: "8px", marginRight: "20px", width: "120px" }}
                src={imageLatinadSSP}
                alt=""
              />
              <a className="link" target="_blank" href="https://latinad.com/medios/ssp"  rel="noopener noreferrer">
                <Button type="primary" shape="round">
                  {t('I have CMS')}
                </Button>
              </a>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            justify="right"
            align="right"
            style={{
              paddingRight: "10%",
            }}
          >
            <img src={displays} alt="" style={{width: "440px"}}/>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="sliderContainer" style={{marginRight: "10%", marginLeft: "10%"}}>
              <SliderSlick {...settings}>
                {this.clients.map((client) => (
                  <div className="sliderCard">
                    <div className="sliderCardContent">
                      <img
                        className="imagePartner"
                        alt="displayers"
                        src={client}
                      />
                    </div>
                  </div>
                ))}
              </SliderSlick>
            </div>
          </Col>
      </InfoHolder>
    );
  }
}
export default withTranslation()(Info);
