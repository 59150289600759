import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import storage from "redux-persist/lib/storage";
import { TRANSLATIONS_ES } from "./translations/es/translation";
import { TRANSLATIONS_ES_AR } from "./translations/es_ar/translation";
import { TRANSLATIONS_EN } from "./translations/en/translation";
import LanguagesEnum from "./constants/LanguagesEnum"
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  es: {
    translation: TRANSLATIONS_ES
  },
  es_ar: {
    translation: TRANSLATIONS_ES_AR
  },
  en: {
    translation: TRANSLATIONS_EN
  }
};

const lang = storage.getItem('language').then((language) => {
  // Set language in localStorage depending on browser.
  if(!language){
    language = navigator.language || navigator.userLanguage;
    if (!LanguagesEnum.includes(language)) {
      if (language.substring(0,2) == "es") language = "es";
      else language = "en";
    }
    storage.setItem("language", language);
  }
  i18n.use(initReactI18next).init({
    resources,
    lng: language,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
});

  export default lang;
